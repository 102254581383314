/* base */
.stkLibPage * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.stkLibPage {
  font-size: 14px;
  font-family: Arial, Helvetica, "Microsoft YaHei New", "Microsoft Yahei",
    "微软雅黑", "宋体", "SimSun", "STXihei", "华文细黑", sans-serif;
  color: initial;
  line-height: initial;
  font-weight: initial;
  width: 100%;
  height: 100%;
}

.stkLibPage article,
aside,
dialog,
footer,
header,
section,
footer,
nav,
figure,
menu {
  display: block;
}

.stkLibPage a {
  text-decoration: none;
  outline: none;
}

.stkLibPage a:active {
  outline: none;
}

.stkLibPage a:focus {
  outline: none;
}

.stkLibPage input {
  outline: none;
  border: none;
}

.stkLibPage button {
  outline: none;
}

.stkLibPage img {
  border: none;
  vertical-align: middle;
}

.stkLibPage ul,
ol,
dl {
  list-style: none;
}

.stkLibPage p {
  line-height: 24px;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.ov {
  clear: both;
  overflow: hidden;
}

@media screen and (max-width: 2000px) {
  .mg {
    max-width: 1200px;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (min-width: 2000px) {
  .mg {
    max-width: 1600px;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1250px) {
  .mg {
    max-width: 1000px;
    display: block;
    margin: 0 auto;
  }
}

.b_all {
  border: 1px solid #ccc;
}

.b_s {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.b_h {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.stkLibPage i {
  font-style: normal;
}

.stkLibPage b {
  font-weight: normal;
}

.stkLibPage h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

.df {
  display: flex;
}

.df_h {
  display: flex;
  flex-direction: row;
}

.df_s {
  display: flex;
  flex-direction: column;
}

.df_hcen {
  align-items: center;
}

.df_scen {
  justify-content: center;
}

.df_allcen {
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.hidden2 {
  position: fixed;
  left: 10000%;
  top: 100000%;
}

.hidden3 {
  visibility: hidden;
}

.page2 {
  background: #000;
}

.tr {
  text-align: right;
}

.tl {
  text-align: left;
}

.page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.page .containers {
  background: white;
  height: 100%;
  flex: 1;
  width: 100%;
  overflow: auto;
}

@media screen and (max-width: 2000px) {
  .checkbox {
    position: relative;
    height: 16px;
  }
  .checkboxAgree {
    height: 68px;
  }
  .checkbox input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    opacity: 0;
  }
  .checkbox label {
    position: absolute;
    left: 30px;
    top: 0;
    height: 16px;
    line-height: 16px;
  }
  .checkbox label:before {
    content: "";
    position: absolute;
    left: -30px;
    top: 0;
    width: 17px;
    height: 17px;
    border: 1px solid #000;
    /*border-radius: 50%;*/
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
  }
  .checkbox label:after {
    content: "";
    position: absolute;
    left: -26px;
    top: 4px;
    width: 11px;
    height: 11px;
    border: 0;
    /*border-radius: 50%;*/
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: #fff;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
  }
}

@media screen and (min-width: 2000px) {
  .checkbox {
    position: relative;
    height: 24px;
  }
  .checkboxAgree {
    height: 76px;
  }
  .checkbox input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    opacity: 0;
  }
  .checkbox label {
    position: absolute;
    left: 30px;
    top: 0;
    height: 24px;
    line-height: 24px;
    font-size: 21px;
  }
  .checkbox label:before {
    content: "";
    position: absolute;
    left: -30px;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    /*border-radius: 50%;*/
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
  }
  .checkbox label:after {
    content: "";
    position: absolute;
    left: -25px;
    top: 5px;
    width: 15px;
    height: 15px;
    border: 0;
    /*border-radius: 50%;*/
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: #fff;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
  }
}

.checkbox input[type="checkbox"]:checked + label:before {
  background: white;
  border-color: #000;
}

.checkbox input[type="checkbox"]:checked + label:after {
  background: #000;
}

.mpad_h {
  padding: 0 1.428571rem;
}

.mpad_s {
  padding: 1.428571rem 0;
}

.mpad_all {
  padding: 1.428571rem;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

/* pop global */
.pop {
  position: fixed;
  width: 650px;
  /*height:370px;*/
  background: rgba(255, 255, 255, 1);
  top: 50%;
  left: 50%;
  z-index: 9999;
  padding: 50px 50px 0 50px;
  margin-top: -150px;
  margin-left: -325px;
}
@media screen and (max-width: 900px) {
  .pop {
    width: 90%;
    left: 50%;
    margin-left: -45%;
  }
}
.pop h3 {
  font-size: 27px;
  margin-top: 66px;
  color: #000;
  margin-bottom: 19px;
}
.pop p {
  font-size: 14px;
  line-height: 17px;
  color: #000;
  margin-bottom: 19px;
}
.pop p.footnote {
  font-size: 11px;
  line-height: 12px;
  color: #777;
}
.pop .btn {
  width: 236px;
  height: 45px;
  border: 2px solid rgba(26, 26, 26, 1);
  text-align: center;
  line-height: 45px;
  margin-bottom: 50px;
  cursor: pointer;
}
.pop .close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.mobilepop {
  width: 21.071429rem;
  position: fixed;
  background: rgba(255, 255, 255, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 50px 0 0 0;
}
.mobilepop h3 {
  margin: 0 auto;
  font-size: 20px;
  line-height: 24px;
  margin-top: 3.714286rem;
  color: #000;
  margin-bottom: 1.357143rem;
  width: 16.857143rem;
  text-align: center;
}
.mobilepop p {
  margin: 0 auto;
  font-size: 13px;
  line-height: 17px;
  color: #000;
  margin-bottom: 1.357143rem;
  width: 16rem;
  text-align: center;
}
.mobilepop p.footnote {
  font-size: 11px;
  color: #777;
}
.mobilepop .btn {
  margin: 0 auto;
  width: 16.857143rem;
  height: 3.214286rem;
  border: 2px solid rgba(26, 26, 26, 1);
  text-align: center;
  line-height: 3.214286rem;
  margin-bottom: 1.428571rem;
}
.mobilepop .close {
  width: 1.428571rem;
  height: 1.428571rem;
  position: absolute;
  right: 1.428571rem;
  top: 1.428571rem;
  cursor: pointer;
}

/* mask */
.mask {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 9990;
}

/* header */
.header {
  width: 100%;
  height: 70px;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.header .mg {
  width: 100%;
}
.header .main {
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
}
.header .main img {
  height: 20px;
}
.header .right {
  position: absolute;
  right: 0.714286rem;
  top: 1rem;
}
.header .right img {
  margin-left: 0.714286rem;
}

.header2 {
  height: 3.214286rem;
}
.header2 .main {
  height: 3.214286rem;
}
.header2 .main img {
  height: 14px;
}

/* Login */
.mobilelogin {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobilelogin .mpad_h {
  padding-top: 50px;
  padding-bottom: 50px;
}
.mobilelogin .main .title {
  font-size: 2.571429rem;
}
.mobilelogin .main p {
  font-size: 1rem;
  margin-top: 0.714286rem;
}
.mobilelogin .main .form .group {
  margin-top: 1.5rem;
}
.mobilelogin .main .form .group input {
  width: 100%;
  padding-bottom: 0.714286rem;
  margin-top: 0.714286rem;
  padding-left: 0;
  border-bottom: 1px solid #4a4a4a;
}
.mobilelogin .main .form .group input::-webkit-input-placeholder,
.mobilelogin .main .form .group input::-moz-placeholder,
.mobilelogin .main .form .group input:-ms-input-placeholder,
.mobilelogin .main .form .group input:-moz-placeholder {
  color: #9b9b9b;
}
.mobilelogin .main .form .btn {
  height: 3.214286rem;
  background: #f8e71c;
  line-height: 3.214286rem;
  text-align: center;
  margin-top: 4.428571rem;
  width: 16.857143rem;
}
.mobilelogin .main .form a {
  text-decoration: underline;
  margin-top: 1.714286rem;
  display: block;
  color: #000;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .main .title {
  width: 487px;
  font-size: 36px;
}
.login .main p {
  font-size: 14px;
  margin-top: 10px;
}
.login .main .form .group {
  margin-top: 21px;
}
.login .main .form .group input {
  width: 507px;
  padding-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
  border-bottom: 1px solid #4a4a4a;
}
.login .main .form .group input::-webkit-input-placeholder,
.login .main .form .group input::-moz-placeholder,
.login .main .form .group input:-ms-input-placeholder,
.login .main .form .group input:-moz-placeholder {
  color: #9b9b9b;
}
.login .main .form .btn {
  width: 236px;
  height: 45px;
  background: #f8e71c;
  line-height: 45px;
  text-align: center;
  margin-top: 37px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}
.login .main .form a {
  text-decoration: underline;
  margin-top: 24px;
  display: block;
  color: #000;
}

@media screen and (min-width: 2000px) {
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login .main .title {
    width: auto;
    font-size: 54px;
  }
  .login .main p {
    font-size: 21px;
    margin-top: 15px;
  }
  .login .main .form .group {
    margin-top: 32px;
  }
  .login .main .form .group input {
    width: 100%;
    padding-bottom: 15px;
    margin-top: 15px;
    padding-left: 0;
    font-size: 21px;
    border-bottom: 1px solid #4a4a4a;
  }
  .login .main .form .group input::-webkit-input-placeholder,
  .login .main .form .group input::-moz-placeholder,
  .login .main .form .group input:-ms-input-placeholder,
  .login .main .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .login .main .form .btn {
    width: auto;
    height: auto;
    background: #f8e71c;
    line-height: 28px;
    text-align: center;
    margin-top: 56px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 21px;
    display: inline-block;
    padding: 15px 100px;
  }
  .login .main .form a {
    text-decoration: underline;
    margin-top: 36px;
    display: block;
    color: #000;
    font-size: 21px;
  }
}

@media screen and (max-width: 600px) {
  .login .main .title {
    width: auto;
  }
  .login .main .form .group input {
    width: 340px;
  }
}

/* forgot */
.mobileforgot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobileforgot .mpad_h {
  padding-top: 50px;
  padding-bottom: 50px;
}
.mobileforgot .main .title {
  font-size: 2.571429rem;
}
.mobileforgot .main p {
  font-size: 1rem;
  margin-top: 0.714286rem;
  width: 20.714286rem;
}
.mobileforgot .main .form .group {
  margin-top: 2.642857rem;
}
.mobileforgot .main .form .group input {
  width: 100%;
  padding-bottom: 0.714286rem;
  margin-top: 0.714286rem;
  padding-left: 0;
  border-bottom: 1px solid #4a4a4a;
}
.mobileforgot .main .form .group input::-webkit-input-placeholder,
.mobileforgot .main .form .group input::-moz-placeholder,
.mobileforgot .main .form .group input:-ms-input-placeholder,
.mobileforgot .main .form .group input:-moz-placeholder {
  color: #9b9b9b;
}
.mobileforgot .main .form .btn {
  width: 16.857143rem;
  height: 3.214286rem;
  background: #f8e71c;
  line-height: 3.214286rem;
  text-align: center;
  margin-top: 2.642857rem;
}
.mobileforgot .main .form .btn2 {
  width: 16.857143rem;
  height: 3.214286rem;
  background: #1a1a1a;
  line-height: 3.214286rem;
  text-align: center;
  margin-top: 2.642857rem;
  color: white;
}
.mobileforgot .main .form .btn2 img {
  width: 0.928571rem;
  height: 0.714286rem;
  margin-right: 0.714286rem;
}

.forgot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.forgot .main .title {
  font-size: 36px;
}
.forgot .main p {
  font-size: 14px;
  margin-top: 10px;
}
.forgot .main .form .group {
  margin-top: 37px;
}
.forgot .main .form .group input {
  width: 507px;
  padding-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
  border-bottom: 1px solid #4a4a4a;
}
.forgot .main .form .group input::-webkit-input-placeholder,
.forgot .main .form .group input::-moz-placeholder,
.forgot .main .form .group input:-ms-input-placeholder,
.forgot .main .form .group input:-moz-placeholder {
  color: #9b9b9b;
}
.forgot .main .form .btn {
  width: 236px;
  height: 45px;
  background: #f8e71c;
  line-height: 45px;
  text-align: center;
  margin-top: 37px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}
.forgot .main .form .btn2 {
  width: 236px;
  height: 45px;
  background: #1a1a1a;
  line-height: 45px;
  text-align: center;
  margin-top: 37px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}
.forgot .main .form .btn2 img {
  width: 13px;
  height: 10px;
  margin-right: 10px;
}

@media screen and (min-width: 2000px) {
  .forgot .main .title {
    font-size: 54px;
  }
  .forgot .main p {
    font-size: 21px;
    margin-top: 15px;
  }
  .forgot .main .form .group {
    margin-top: 56px;
  }
  .forgot .main .form .group input {
    width: 100%;
    padding-bottom: 15px;
    margin-top: 15px;
    padding-left: 0;
    font-size: 21px;
    border-bottom: 1px solid #4a4a4a;
  }
  .forgot .main .form .group input::-webkit-input-placeholder,
  .forgot .main .form .group input::-moz-placeholder,
  .forgot .main .form .group input:-ms-input-placeholder,
  .forgot .main .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .forgot .main .form .btn {
    width: auto;
    height: auto;
    background: #f8e71c;
    line-height: 28px;
    text-align: center;
    margin-top: 56px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 21px;
    display: inline-block;
    padding: 15px 100px;
  }
  .forgot .main .form .btn2 {
    width: auto;
    height: auto;
    background: #1a1a1a;
    line-height: 28px;
    text-align: center;
    margin-top: 56px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 21px;
    display: inline-block;
    padding: 15px 100px;
  }
  .forgot .main .form .btn2 img {
    width: 20px;
    height: 15px;
    margin-right: 15px;
  }
}

@media screen and (max-width: 600px) {
  .forgot .main .form .group input {
    width: 340px;
  }
}

/* User */
.user .main {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.navbar {
  background: white;
  height: 3.571429rem;
  display: block;
}
.navbar li {
  width: 33.33%;
  display: inline-flex;
  justify-content: center;
  background: #ededed;
  line-height: 3.571429rem;
}
.navbar li.active {
  background: white;
}

.user .main {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.user .mainleft {
  width: 227px;
  height: 100%;
  background: #ededed;
  position: relative;
  padding: 40px 39px;
}
.user .mainleft ul li {
  font-size: 20px;
  padding-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.user .mainleft ul li.active {
  border-bottom: 1px solid #000;
}
.user .mainleft .logout {
  position: absolute;
  bottom: 40px;
  cursor: pointer;
}
.user .mainleft .logout img {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

@media screen and (min-width: 2000px) {
  .user .main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
  .user .mainleft {
    width: 300px;
    height: 100%;
    background: #ededed;
    position: relative;
    padding: 40px 39px;
  }
  .user .mainleft ul li {
    font-size: 30px;
    padding-bottom: 15px;
    margin-top: 15px;
    cursor: pointer;
  }
  .user .mainleft ul li.active {
    border-bottom: 1px solid #000;
  }
  .user .mainleft .logout {
    position: absolute;
    bottom: 60px;
    cursor: pointer;
    font-size: 21px;
  }
  .user .mainleft .logout img {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 22px;
  }
}

/* OrderHistory */
.mobileorderhistory {
  height: 75vh;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.mobileorderhistory .title {
  font-size: 24px;
  margin-top: 24px;
  margin-bottom: 20px;
}
.mobileorderhistory .title span {
  font-size: 14px;
  line-height: 1.714286rem;
}
.mobileorderhistory .title span i {
  font-size: 14px;
}
.mobileorderhistory .tabel {
  flex: 1;
  margin-bottom: 100px;
}
.mobileorderhistory .tabel .order-detail-title {
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 3px;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
}
.mobileorderhistory .tabel .item {
  line-height: 16px;
}
.mobileorderhistory .tabel .thead {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.714286rem;
  color: #777;
}
.mobileorderhistory .tabel .thead .item {
  flex: 1;
}
.mobileorderhistory .tabel .thead .date {
  width: 12.857143rem;
}
.mobileorderhistory .tabel .thead .status {
  width: 12.857143rem;
}
.mobileorderhistory .tabel .tbody {
  display: flex;
  margin-top: 1.428571rem;
}
.mobileorderhistory .tabel .tbody .item {
  flex: 1;
  display: flex;
}
.mobileorderhistory .tabel .tbody .item .text .name {
  font-size: 1.428571rem;
  width: 28.571429rem;
}
.mobileorderhistory .tabel .tbody .item .text img {
  width: 7.571429rem;
  height: 2.5rem;
}
.mobileorderhistory .tabel .tbody .item .text .qty {
  margin-top: 0.428571rem;
  margin-bottom: 0.642857rem;
}
.mobileorderhistory .tabel .tbody .item .text .price {
  color: #ff8800;
  margin-top: 0.571429rem;
}
.mobileorderhistory .tabel .tbody .item .itemimg {
  width: 14.071429rem;
  height: 8rem;
  margin-right: 1.428571rem;
}
.mobileorderhistory .tabel .tbody .date {
  width: 12.857143rem;
}
.mobileorderhistory .tabel .tbody .status {
  width: 12.857143rem;
}
.mobileorderhistory .tabel .group {
  width: 100%;
  margin-top: 0.928rem;
}
.mobileorderhistory .tabel .group .item {
  margin-top: 0.714286rem;
}
.mobileorderhistory .tabel .group .item .itemimg {
  width: 100%;
  margin-bottom: 10px;
}
.mobileorderhistory .tabel .group .item .text .name {
  font-size: 14px;
}
.mobileorderhistory .tabel .group .qty {
  margin-top: 0.857143rem;
}
.mobileorderhistory .tabel .group .img {
  width: 7.571429rem;
  height: 2.5rem;
  margin-top: 0.571429rem;
  margin-bottom: 0.642857rem;
}
.mobileorderhistory .tabel .group .date {
  margin-top: 0.857143rem;
}
.mobileorderhistory .tabel .group .status {
  margin-top: 0.857143rem;
}
.mobileorderhistory .bot {
  position: fixed;
  bottom: 1.5rem;
  left: 0px;
  color: #fff;
  padding: 0px 20px;
}
.mobileorderhistory .bottext1 .price {
  color: #ff8800;
}
.mobileorderhistory .bottext2 .price {
  color: #000;
}
.mobileorderhistory .maingroup {
  height: auto;
}

.orderhistory {
  padding-left: 66px;
  padding-right: 66px;
  height: 100%;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.orderhistory .title {
  font-size: 36px;
  margin-top: 69px;
  margin-bottom: 18px;
}
.orderhistory .maintable {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.orderhistory .tabel {
  flex: 1;
  margin-top: 10px;
  margin-bottom: 100px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.orderhistory .tabel .order-detail-title {
  font-size: 22px;
  line-height: 26px;
  padding-bottom: 3px;
  border-bottom: 1px solid #000;
  margin-bottom: 20px;
}
.orderhistory .tabel .order-detail-line {
  font-size: 16px;
  line-height: 20px;
  display: flex;
}
.orderhistory .tabel .order-detail-line .order-detail-left {
  width: 200px;
}
.orderhistory .tabel .thead {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-top: 20px;
  color: #777;
}
.orderhistory .tabel .thead .item {
  flex: 1;
}
.orderhistory .tabel .thead .date {
  width: 180px;
  text-align: left;
}
.orderhistory .tabel .thead .status {
  width: 180px;
  text-align: left;
}
.orderhistory .tabel .tbody {
  display: flex;
  margin-top: 20px;
}
.orderhistory .tabel .tbody .item {
  flex: 1;
  display: flex;
}
.orderhistory .tabel .tbody .item .text .name {
  font-size: 18px;
  line-height: 22px;
  min-width: 200px;
}
.orderhistory .tabel .tbody .item .text img {
  width: 106px;
  height: 35px;
}
.orderhistory .tabel .tbody .item .text .qty {
  margin-top: 6px;
  margin-bottom: 9px;
}
.orderhistory .tabel .tbody .item .text .price {
  color: #ff8800;
  margin-top: 8px;
}
.orderhistory .tabel .tbody .item .text1 .price {
  color: #ff8800;
}
.orderhistory .tabel .tbody .item .text2 .price {
  color: #000;
}
.orderhistory .tabel .tbody .item .itemimg {
  width: 280px;
  height: 160px;
  margin-right: 20px;
}
.orderhistory .tabel .tbody .date {
  width: 180px;
}
.orderhistory .tabel .tbody .status {
  width: 180px;
}
.orderhistory .bot {
  margin-bottom: 40px;
  margin-top: 10px;
}

@media screen and (min-width: 2000px) {
  .orderhistory {
    padding-left: 99px;
    padding-right: 99px;
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .orderhistory .title {
    font-size: 54px;
    margin-top: 103px;
    margin-bottom: 27px;
  }
  .orderhistory .maintable {
    flex: 1;
    height: 100%;
    overflow: auto;
  }
  .orderhistory .tabel {
    flex: 1;
    margin-top: 15px;
    margin-bottom: 150px;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .orderhistory .tabel .order-detail-title {
    font-size: 33px;
    line-height: 39px;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
    margin-bottom: 30px;
  }
  .orderhistory .tabel .order-detail-line {
    font-size: 24px;
    line-height: 30px;
    display: flex;
  }
  .orderhistory .tabel .order-detail-line .order-detail-left {
    width: 300px;
  }
  .orderhistory .tabel .thead {
    display: flex;
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
    margin-top: 30px;
    font-size: 21px;
  }
  .orderhistory .tabel .thead .item {
    flex: 1;
  }
  .orderhistory .tabel .thead .date {
    width: 270px;
    text-align: left;
  }
  .orderhistory .tabel .thead .status {
    width: 270px;
    text-align: left;
  }
  .orderhistory .tabel .tbody {
    display: flex;
    margin-top: 30px;
  }
  .orderhistory .tabel .tbody .item {
    flex: 1;
    display: flex;
  }
  .orderhistory .tabel .tbody .item .text .name {
    font-size: 27px;
    line-height: 33px;
    min-width: 300px;
  }
  .orderhistory .tabel .tbody .item .text img {
    width: 159px;
    height: 52px;
  }
  .orderhistory .tabel .tbody .item .text .qty {
    margin-top: 9px;
    margin-bottom: 13px;
    font-size: 21px;
  }
  .orderhistory .tabel .tbody .item .text .price {
    color: #ff8800;
    margin-top: 12px;
    font-size: 21px;
  }
  .orderhistory .tabel .tbody .item .text1 .price {
    color: #ff8800;
  }
  .orderhistory .tabel .tbody .item .text2 .price {
    color: #000;
  }
  .orderhistory .tabel .tbody .item .itemimg {
    width: 420px;
    height: 240px;
    margin-right: 30px;
  }
  .orderhistory .tabel .tbody .date {
    width: 270px;
  }
  .orderhistory .tabel .tbody .status {
    width: 270px;
    font-size: 21px;
  }
  .orderhistory .bot {
    margin-bottom: 60px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .orderhistory {
    min-width: 750px;
  }
}

/* paging */
.order-pagination {
  padding-left: 1%;
}

.order-pagination.morder-paging {
  padding: 0 1.428571rem;
}

.order-pagination {
  line-height: 3;
}

.order-pagination li {
  display: inline-block;
}

.order-pagination a {
  border: 1px solid #ddd;
  border-right: 0;
  padding: 6px 12px;
  font-size: 0.8rem;
  cursor: pointer;
  color: black;
  margin-bottom: 40px;
}

.order-pagination .first a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.order-pagination .last a {
  border-right: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.order-pagination .last a {
  border-right: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.order-pagination .active a {
  cursor: default;
  pointer-events: none;
  background-color: black;
  border-color: black;
  color: rgb(255, 255, 255);
}

.order-pagination .disabled a {
  cursor: default;
  pointer-events: none;
  color: rgb(165, 165, 165);
}

.mTable {
  overflow: auto;
  flex: 1;
}

.mTable > div {
  padding: 0 1.428571rem;
}

.orderWrapper {
  display: flex;
  flex-direction: column;
  height: calc(98vh - 3.571429rem - 3.214286rem);
  height: calc(var(--vh, 1vh) * 100 - 3.571429rem - 3.214286rem);
  overflow: hidden;
}

/* preloader */
.preloader-container {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
}

.preloader {
  width: 125px;
  height: 125px;
  position: absolute;
  top: 50%;
  margin-top: -62px;
  left: 50%;
  margin-left: -62px;
}
.preloader img {
  width: 100%;
  height: 100%;
}

/* Profile */
.proile {
  padding-left: 66px;
  height: 100%;
  overflow: auto;
  width: 100%;
}
.proile .form .title {
  font-size: 36px;
  margin-top: 54px;
}
.proile .form .group {
  margin-top: 23px;
}
.proile .form .group img {
  margin-left: -23px;
  cursor: pointer;
}
.proile .form .group input {
  width: 700px;
  padding-bottom: 10px;
  margin-top: 10px;
  padding-left: 0;
  border-bottom: 1px solid #4a4a4a;
  font-size: 18px;
  padding-right: 23px;
}
.proile .form .group input::-webkit-input-placeholder,
.proile .form .group input::-moz-placeholder,
.proile .form .group input:-ms-input-placeholder,
.proile .form .group input:-moz-placeholder {
  color: #9b9b9b;
}
.proile .form .country-dropdown-container {
  width: 700px;
}
.proile .btn {
  width: 236px;
  height: 45px;
  border: 2px solid black;
  line-height: 45px;
  text-align: center;
  margin-top: 23px;
  margin-bottom: 100px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}

@media screen and (min-width: 2000px) {
  .proile {
    padding-left: 99px;
    height: 100%;
    overflow: auto;
    width: 100%;
  }
  .proile .form .title {
    font-size: 54px;
    margin-top: 81px;
  }
  .proile .form p {
    font-size: 21px;
    line-height: 24px;
  }
  .proile .form .group {
    margin-top: 34px;
  }
  .proile .form .group p {
    font-size: 21px;
  }
  .proile .form .group img {
    margin-left: -34px;
    cursor: pointer;
  }
  .proile .form .group input {
    width: 1050px;
    padding-bottom: 15px;
    margin-top: 15px;
    padding-left: 0;
    border-bottom: 1px solid #4a4a4a;
    font-size: 27px;
    padding-right: 34px;
  }
  .proile .form .group input::-webkit-input-placeholder,
  .proile .form .group input::-moz-placeholder,
  .proile .form .group input:-ms-input-placeholder,
  .proile .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .proile .form .country-dropdown-container {
    width: 1050px;
  }
  .proile .btn {
    width: 354px;
    height: 68px;
    border: 2px solid black;
    line-height: 68px;
    text-align: center;
    margin-top: 34px;
    margin-bottom: 150px;
    cursor: pointer;
    font-size: 21px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.mobileproile {
  height: 100%;
  overflow: auto;
  width: 100%;
}
.mobileproile .form .title {
  font-size: 1.714286rem;
  margin-top: 3.857143rem;
}
.mobileproile .form .group {
  margin-top: 1.642857rem;
}
.mobileproile .form .group img {
  margin-left: -1.642857rem;
  cursor: pointer;
}
.mobileproile .form .group input {
  width: 100%;
  padding-bottom: 0.714286rem;
  margin-top: 0.714286rem;
  padding-left: 0;
  border-bottom: 1px solid #4a4a4a;
  font-size: 1.285714rem;
  padding-right: 1.642857rem;
}
.mobileproile .form .group input::-webkit-input-placeholder,
.mobileproile .form .group input::-moz-placeholder,
.mobileproile .form .group input:-ms-input-placeholder,
.mobileproile .form .group input:-moz-placeholder {
  color: #9b9b9b;
}
.mobileproile .btn {
  width: 16.857143rem;
  height: 3.214286rem;
  border: 0.14286rem solid black;
  line-height: 3.214286rem;
  text-align: center;
  margin-top: 1.642857rem;
  margin-bottom: 1.428571rem;
}

/* Subscription */
.mobilesubscriptions .tabel {
  border-top: 1px solid #000;
}
.mobilesubscriptions .tabel .item {
  font-size: 16px;
  line-height: 20px;
}
.mobilesubscriptions .tabel .subscriptions-title {
  font-size: 20px;
  line-height: 28px;
}
.mobilesubscriptions .tabel .itemimg {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 15px;
}

.mobilesubscriptions .pop h3 {
  margin-top: 36px;
}

.mobileorderhistory .btn-link {
  cursor: pointer;
  margin-top: 10px;
  font-size: 12px;
  text-decoration: underline;
  color: #777;
}

.mobileorderhistory .mask {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 9990;
}

.mobileorderhistory .pop {
  width: 90%;
  /*height: 250px;*/
  top: 50%;
  margin-top: -125px;
  left: 50%;
  margin-left: -45%;
  padding: 20px;
}
.mobileorderhistory .pop .h3 {
  margin-top: 5px;
  font-size: 24px;
}
.mobileorderhistory .pop .btn {
  float: none;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
}
.mobileorderhistory .pop .btn-confirm {
  background-color: #000;
  color: #fff;
}
.mobileorderhistory .pop .btn-confirm:hover {
  background-color: #fff;
  color: #000;
}
.mobileorderhistory .pop .btn-cancel {
  background-color: #fff;
  color: #000;
}
.mobileorderhistory .pop .btn-cancel:hover {
  background-color: #000;
  color: #fff;
}
.mobileorderhistory .pop .close {
  right: 20px;
  top: 20px;
}

.mobileorderhistory .bottext1 .price {
  color: #ff8800;
}

.mobileorderhistory .bottext2 .price {
  color: #000;
}

.mobileorderhistory .maingroup {
  height: auto;
}

.subscriptions .tabel {
  border-top: 1px solid #000;
}
.subscriptions .tabel .item {
  font-size: 16px;
  line-height: 20px;
}
.subscriptions .tabel .subscriptions-title {
  font-size: 20px;
  line-height: 28px;
}
.subscriptions .tabel .itemimg {
  width: 280px;
  height: 160px;
  margin-bottom: 10px;
}

.subscriptions .pop h3 {
  margin-top: 36px;
}

.orderhistory .btn-link {
  cursor: pointer;
  margin-top: 10px;
  font-size: 12px;
  text-decoration: underline;
  color: #777;
}

.orderhistory .mask {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 9990;
}

.orderhistory .pop {
  width: 550px;
  height: 300px;
  top: 40%;
  margin-top: -150px;
  left: 50%;
  margin-left: -275px;
  padding: 50px;
}
.orderhistory .pop .btn {
  float: left;
  margin-right: 20px;
  width: 200px;
  cursor: pointer;
}
.orderhistory .pop .btn-confirm {
  background-color: #000;
  color: #fff;
}
.orderhistory .pop .btn-confirm:hover {
  background-color: #fff;
  color: #000;
}
.orderhistory .pop .btn-cancel {
  background-color: #fff;
  color: #000;
}
.orderhistory .pop .btn-cancel:hover {
  background-color: #000;
  color: #fff;
}
.orderhistory .pop .close {
  right: 20px;
  top: 20px;
}

@media screen and (min-width: 2000px) {
  .subscriptions .tabel .item {
    font-size: 24px;
    line-height: 30px;
  }
  .subscriptions .tabel .subscriptions-title {
    font-size: 30px;
    line-height: 42px;
  }
  .subscriptions .tabel .itemimg {
    width: 420px;
    height: 240px;
    margin-bottom: 15px;
  }
  .subscriptions .pop h3 {
    margin-top: 54px;
    font-size: 40px;
    margin-bottom: 28px;
  }
  .subscriptions .pop p {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 28px;
  }
  .subscriptions .pop .btn {
    margin-right: 30px;
    width: 300px;
    height: 68px;
    line-height: 68px;
    font-size: 21px;
  }
  .orderhistory .btn-link {
    margin-top: 15px;
    font-size: 18px;
  }
  .orderhistory .pop {
    width: 825px;
    height: 450px;
    top: 40%;
    margin-top: -225px;
    left: 50%;
    margin-left: -412px;
    padding: 75px;
  }
  .orderhistory .pop .close {
    right: 30px;
    top: 30px;
  }
}

/* Cart1 */
.term-duration {
  position: relative;
  display: inline-block;
  width: 40px;
  margin-top: 0px;
  margin-right: 20px;
  margin-bottom: 15px;
}

.term-duration input {
  width: 40px;
  flex: 1;
  padding-bottom: 10px;
  padding-left: 0;
  border-bottom: 1px solid #000;
  font-size: 16px;
}

.checkbox-paybyterm {
  margin-bottom: 20px;
}

.inst-amt {
  margin-bottom: 20px;
  font-size: 16px;
}
.cart .pop {
  width: 550px;
  height: 300px;
  top: 40%;
  margin-top: -150px;
  left: 50%;
  margin-left: -275px;
  padding: 50px;
}

.cart .pop h3 {
  margin-top: 20px;
}

.cart .pop .btn {
  float: left;
  margin-right: 20px;
  width: 200px;
  cursor: pointer;
}

.cart .pop .btn-confirm {
  background-color: #000;
  color: #fff;
}

.cart .pop .btn-confirm:hover {
  background-color: #fff;
  color: #000;
}

.cart .pop .btn-cancel {
  background-color: #fff;
  color: #000;
}

.cart .pop .btn-cancel:hover {
  background-color: #000;
  color: #fff;
}

.cart .pop .close {
  right: 20px;
  top: 20px;
}

.shipping-delivery-date {
  margin-top: 15px;
}

.shipping-notice {
  margin-top: 15px;
}

.empty-cart-wrapper {
  text-align: center;
  padding: 50px;
}

.empty-cart-image {
  width: 128px;
  height: 128px;
}

.empty-cart-heading {
  margin-top: 25px;
  font-size: 28px;
  font-weight: bold;
}

.empty-cart-p {
  margin-top: 20px;
  font-size: 16px;
}

.empty-cart-p2 {
  margin-top: 5px;
  font-size: 16px;
}
.empty-cart-p2 a {
  color: #000;
  text-decoration: underline;
}

.cart-checkmark {
  width: 12px;
  height: 12px;
  margin-top: -4px;
}

.cart .main .tabel .tabelbot .right p.payment-due-today {
  color: #28a6d4;
}

.btn-discount-code {
  background: #f8e71c;
  font-size: 14px;
  color: #000;
  line-height: 30px;
  text-align: center;
  padding: 0 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #f8e71c;
}

.discount-code-container {
  margin-bottom: 20px;
}

.discount-code-error {
  color: red;
  margin-bottom: 20px;
}

.discount-code-container input {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  padding-left: 0;
  font-size: 16px;
  flex: 1 1;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

.pts-long p {
  position: relative;
  padding-left: 20px;
  line-height: 17px;
  margin-bottom: 8px;
}

.pts-long p img {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 0px;
}

@media screen and (max-width: 2000px) {
  .cart .topheader {
    display: flex;
    padding-top: 45px;
  }
  .cart .topheader .li {
    margin-right: 30px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 8px;
    width: 22px;
    height: 22px;
    line-height: 20px;
    font-size: 14px;
  }
  .cart .topheader .li i {
    color: #000000;
    font-size: 14px;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .main .title {
    color: #000;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 36px;
  }
  .cart .main .tabel .tabelheader {
    display: flex;
    margin-bottom: 10px;
  }
  .cart .main .tabel .tabelheader .desc {
    flex: 1;
    font-size: 14px;
  }
  .cart .main .tabel .tabelheader .qty {
    width: 225px;
  }
  .cart .main .tabel .tabelheader .price {
    width: 111px;
  }
  .cart .main .tabel .tabelmain {
    display: flex;
    border-top: 1px solid #000;
    padding: 25px 0;
  }
  .cart .main .tabel .tabelmain .desc {
    flex: 1;
    display: flex;
  }
  .cart .main .tabel .tabelmain .desc .img {
    margin-right: 20px;
  }
  .cart .main .tabel .tabelmain .desc .img img {
    width: 430px;
    height: 239px;
    display: block;
  }
  .cart .main .tabel .tabelmain .desc .desctext {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 img {
    margin-top: 10px;
    margin-bottom: 3px;
    width: 115px;
    height: 43px;
    margin-right: 72px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 p {
    font-size: 16px;
    color: #ff8800;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 u {
    margin-top: 15px;
    display: block;
    cursor: pointer;
    font-size: 14px;
    color: #777;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .name-series {
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .name {
    font-size: 24px;
    color: #000;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .desc {
    font-size: 16px;
    margin-top: 20px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .p {
    font-size: 16px;
    color: #9b9b9b;
    margin-top: 20px;
    width: 195px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .plaque-name {
    font-size: 16px;
    color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .plaque-creditline {
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 {
    display: flex;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 .text {
    color: #ff8800;
    font-size: 16px;
    margin-left: 10px;
    padding-top: 5px;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 .text p {
    color: #ff8800;
    font-size: 16px;
    line-height: 20px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 img {
    width: 115px;
    height: 43px;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .qty {
    width: 195px;
    display: flex;
  }
  .cart .main .tabel .tabelmain .qty span {
    width: 40px;
    height: 40px;
    border: 1px solid #4a4a4a;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .qty input {
    width: 40px;
    height: 40px;
    border-top: 1px solid #4a4a4a;
    border-bottom: 1px solid #4a4a4a;
    font-size: 16px;
    color: #4a4a4a;
    padding-left: 0;
    text-align: center;
  }
  .cart .main .tabel .tabelmain .qty input::-webkit-outer-spin-button,
  .cart .main .tabel .tabelmain .qty input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .cart .main .tabel .tabelmain .qty-fixed {
    font-size: 16px;
  }
  .cart .main .tabel .tabelmain .price {
    width: 140px;
    text-align: right;
  }
  .cart .main .tabel .tabelmain .price .oldprice {
    color: #000000;
    font-size: 16px;
    text-decoration: line-through;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelmain .price .newprice {
    color: #000000;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelmain .price .mon {
    color: #000000;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .cart .main .tabel .tabelmain .price .deposit {
    margin-bottom: 20px;
    color: #28a6d4;
    font-size: 16px;
  }
  .cart .main .tabel .tabelmain .price .del {
    color: #000000;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
  }
  .cart .main .tabel .tabelbot {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    border-top: 1px solid #000;
  }
  .cart .main .tabel .tabelbot .left {
    display: flex;
  }
  .cart .main .tabel .tabelbot .left .cart-subscription-disclaimer {
    width: 440px;
    font-size: 12px;
    line-height: 15px;
  }
  .cart .main .tabel .tabelbot .left .text {
    font-size: 16px;
    color: #ff8800;
  }
  .cart .main .tabel .tabelbot .left img {
    width: 115px;
    height: 38px;
    margin-left: 12px;
  }
  .cart .main .tabel .tabelbot .right p {
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-size: 16px;
  }
  .cart .main .tabel .tabelbot .right p.p1 {
    font-size: 16px;
  }
  .cart .main .tabel .tabelbot .right p.p1 span {
    font-size: 16px;
    margin-left: 15px;
  }
  .cart .main .tabel .tabelbot .right p.p1 i {
    font-size: 14px;
  }
  .cart .main .tabel .tabelbot .right p.p2 {
    font-size: 14px;
    margin-top: 22px;
    border-top: 1px solid #000;
    padding-top: 22px;
  }
  .cart .main .tabel .tabelbot .right .btn {
    width: 236px;
    height: 45px;
    background: #f8e71c;
    font-size: 14px;
    color: #000;
    line-height: 45px;
    text-align: center;
    padding: 0;
    float: right;
    margin-top: 24px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .main .tabel .tabelbot .right .btn2 {
    width: 236px;
    height: 45px;
    background: #fff;
    border: 1px solid #000;
    font-size: 14px;
    color: #000;
    line-height: 45px;
    text-align: center;
    padding: 0;
    float: right;
    margin-top: 24px;
    cursor: pointer;
    text-transform: uppercase;
  }
  .cart .main .tabel .tabelbot .right .continue-shopping-link {
    width: 236px;
    float: right;
    margin-top: 30px;
    display: block;
    text-align: center;
    text-decoration: underline;
    text-transform: capitalize;
    color: #000;
  }
}

@media screen and (min-width: 2000px) {
  .cart .topheader {
    display: flex;
    padding-top: 60px;
  }
  .cart .topheader .li {
    margin-right: 40px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 11px;
    width: 30px;
    height: 30px;
    line-height: 26px;
    font-size: 19px;
  }
  .cart .topheader .li i {
    color: #000000;
    font-size: 19px;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .main .title {
    color: #000;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 48px;
  }
  .cart .main .tabel .continue-shopping-link {
    font-size: 19px;
  }
  .cart .main .tabel .tabelheader {
    display: flex;
    margin-bottom: 13px;
  }
  .cart .main .tabel .tabelheader .desc {
    flex: 1;
    font-size: 19px;
  }
  .cart .main .tabel .tabelheader .qty {
    width: 300px;
    font-size: 19px;
  }
  .cart .main .tabel .tabelheader .price {
    width: 148px;
    font-size: 19px;
  }
  .cart .main .tabel .tabelmain {
    display: flex;
    border-top: 1px solid #000;
    padding: 33px 0;
  }
  .cart .main .tabel .tabelmain .shipping-delivery-date {
    font-size: 19px;
  }
  .cart .main .tabel .tabelmain .cart-checkmark {
    width: 16px;
    height: 16px;
    margin-top: -5px;
  }
  .cart .main .tabel .tabelmain p {
    font-size: 19px;
  }
  .cart .main .tabel .tabelmain .desc {
    flex: 1;
    display: flex;
  }
  .cart .main .tabel .tabelmain .desc .img {
    margin-right: 27px;
  }
  .cart .main .tabel .tabelmain .desc .img img {
    width: 573px;
    height: 318px;
    display: block;
  }
  .cart .main .tabel .tabelmain .desc .desctext {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
  .cart .main .tabel .tabelmain .desc .desctext .shipping-notice {
    font-size: 19px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 img {
    margin-top: 13px;
    margin-bottom: 4px;
    width: 153px;
    height: 57px;
    margin-right: 96px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 p {
    font-size: 21px;
    color: #ff8800;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 u {
    margin-top: 20px;
    display: block;
    cursor: pointer;
    font-size: 19px;
    color: #777;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .name-series {
    font-size: 21px;
    color: #000;
    margin-bottom: 13px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .name {
    font-size: 32px;
    color: #000;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .desc {
    font-size: 21px;
    margin-top: 27px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .p {
    font-size: 21px;
    color: #9b9b9b;
    margin-top: 27px;
    width: 260px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .plaque-name {
    font-size: 21px;
    color: #000;
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .plaque-creditline {
    font-size: 21px;
    color: #000;
    margin-bottom: 13px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 {
    display: flex;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 .text {
    color: #ff8800;
    font-size: 21px;
    margin-left: 13px;
    padding-top: 7px;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 .text p {
    color: #ff8800;
    font-size: 21px;
    line-height: 27px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 img {
    width: 153px;
    height: 57px;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .qty {
    width: 260px;
    display: flex;
  }
  .cart .main .tabel .tabelmain .qty span {
    width: 53px;
    height: 53px;
    border: 1px solid #4a4a4a;
    line-height: 53px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .qty input {
    width: 53px;
    height: 53px;
    border-top: 1px solid #4a4a4a;
    border-bottom: 1px solid #4a4a4a;
    font-size: 21px;
    color: #4a4a4a;
    padding-left: 0;
    text-align: center;
  }
  .cart .main .tabel .tabelmain .qty input::-webkit-outer-spin-button,
  .cart .main .tabel .tabelmain .qty input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .cart .main .tabel .tabelmain .qty-fixed {
    font-size: 21px;
  }
  .cart .main .tabel .tabelmain .price {
    width: 187px;
    text-align: right;
  }
  .cart .main .tabel .tabelmain .price .oldprice {
    color: #000000;
    font-size: 21px;
    text-decoration: line-through;
    margin-bottom: 13px;
  }
  .cart .main .tabel .tabelmain .price .newprice {
    color: #000000;
    font-size: 21px;
    margin-bottom: 13px;
  }
  .cart .main .tabel .tabelmain .price .mon {
    color: #000000;
    margin-bottom: 27px;
    font-size: 19px;
  }
  .cart .main .tabel .tabelmain .price .deposit {
    margin-bottom: 27px;
    font-size: 21px;
    color: #28a6d4;
  }
  .cart .main .tabel .tabelmain .price .del {
    color: #000000;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
    font-size: 19px;
  }
  .cart .main .tabel .tabelbot {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    border-top: 1px solid #000;
  }
  .cart .main .tabel .tabelbot .left {
    display: flex;
  }
  .cart .main .tabel .tabelbot .left .cart-subscription-disclaimer {
    width: 587px;
    font-size: 16px;
    line-height: 20px;
  }
  .cart .main .tabel .tabelbot .left .text {
    font-size: 21px;
    color: #ff8800;
  }
  .cart .main .tabel .tabelbot .left img {
    width: 153px;
    height: 51px;
    margin-left: 16px;
  }
  .cart .main .tabel .tabelbot .right p {
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-size: 21px;
    line-height: 32px;
  }
  .cart .main .tabel .tabelbot .right p.p1 {
    font-size: 21px;
  }
  .cart .main .tabel .tabelbot .right p.p1 span {
    font-size: 21px;
    margin-left: 20px;
  }
  .cart .main .tabel .tabelbot .right p.p1 i {
    font-size: 19px;
  }
  .cart .main .tabel .tabelbot .right p.p2 {
    font-size: 19px;
    margin-top: 29px;
    border-top: 1px solid #000;
    padding-top: 29px;
  }
  .cart .main .tabel .tabelbot .right .btn {
    width: 315px;
    height: 60px;
    background: #f8e71c;
    font-size: 19px;
    color: #000;
    line-height: 60px;
    text-align: center;
    padding: 0;
    float: right;
    margin-top: 32px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .main .tabel .tabelbot .right .btn2 {
    width: 315px;
    height: 60px;
    background: #fff;
    border: 1px solid #000;
    font-size: 19px;
    color: #000;
    line-height: 60px;
    text-align: center;
    padding: 0;
    float: right;
    margin-top: 32px;
    cursor: pointer;
    text-transform: uppercase;
  }
  .cart .main .tabel .tabelbot .right .continue-shopping-link {
    width: 315px;
    float: right;
    margin-top: 40px;
    display: block;
    text-align: center;
    text-decoration: underline;
    text-transform: capitalize;
    color: #000;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1250px) {
  .cart .topheader {
    display: flex;
    padding-top: 38px;
  }
  .cart .topheader .li {
    margin-right: 25px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 7px;
    width: 18px;
    height: 18px;
    line-height: 16px;
    font-size: 12px;
  }
  .cart .topheader .li i {
    color: #000000;
    font-size: 12px;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .main .title {
    color: #000;
    margin-top: 25px;
    margin-bottom: 13px;
    font-size: 30px;
  }
  .cart .main .tabel .continue-shopping-link {
    font-size: 12px;
  }
  .cart .main .tabel .tabelheader {
    display: flex;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelheader .desc {
    flex: 1;
    font-size: 12px;
  }
  .cart .main .tabel .tabelheader .qty {
    width: 187px;
    font-size: 12px;
  }
  .cart .main .tabel .tabelheader .price {
    width: 92px;
    font-size: 12px;
  }
  .cart .main .tabel .tabelmain {
    display: flex;
    border-top: 1px solid #000;
    padding: 21px 0;
  }
  .cart .main .tabel .tabelmain .shipping-delivery-date {
    font-size: 12px;
    margin-top: 0px;
  }
  .cart .main .tabel .tabelmain .cart-checkmark {
    width: 10px;
    height: 10px;
    margin-top: -3px;
  }
  .cart .main .tabel .tabelmain p {
    font-size: 12px;
  }
  .cart .main .tabel .tabelmain .desc {
    flex: 1;
    display: flex;
  }
  .cart .main .tabel .tabelmain .desc .img {
    margin-right: 17px;
  }
  .cart .main .tabel .tabelmain .desc .img img {
    width: 358px;
    height: 199px;
    display: block;
  }
  .cart .main .tabel .tabelmain .desc .desctext {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
  .cart .main .tabel .tabelmain .desc .desctext .shipping-notice {
    font-size: 12px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 img {
    margin-top: 8px;
    margin-bottom: 3px;
    width: 96px;
    height: 36px;
    margin-right: 60px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 p {
    font-size: 13px;
    color: #ff8800;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 u {
    margin-top: 13px;
    display: block;
    cursor: pointer;
    font-size: 12px;
    color: #777;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .name-series {
    font-size: 14px;
    color: #000;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .name {
    font-size: 20px;
    color: #000;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .desc {
    font-size: 13px;
    margin-top: 17px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .p {
    font-size: 13px;
    color: #9b9b9b;
    margin-top: 17px;
    width: 162px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .plaque-name {
    font-size: 14px;
    color: #000;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .plaque-creditline {
    font-size: 14px;
    color: #000;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 {
    display: flex;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 .text {
    color: #ff8800;
    font-size: 13px;
    margin-left: 8px;
    padding-top: 4px;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 .text p {
    color: #ff8800;
    font-size: 13px;
    line-height: 17px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 img {
    width: 96px;
    height: 36px;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .qty {
    width: 162px;
    display: flex;
  }
  .cart .main .tabel .tabelmain .qty span {
    width: 33px;
    height: 33px;
    border: 1px solid #4a4a4a;
    line-height: 33px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .qty input {
    width: 33px;
    height: 33px;
    border-top: 1px solid #4a4a4a;
    border-bottom: 1px solid #4a4a4a;
    font-size: 13px;
    color: #4a4a4a;
    padding-left: 0;
    text-align: center;
  }
  .cart .main .tabel .tabelmain .qty input::-webkit-outer-spin-button,
  .cart .main .tabel .tabelmain .qty input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .cart .main .tabel .tabelmain .qty-fixed {
    font-size: 14px;
  }
  .cart .main .tabel .tabelmain .price {
    width: 117px;
    text-align: right;
  }
  .cart .main .tabel .tabelmain .price .oldprice {
    color: #000000;
    font-size: 14px;
    text-decoration: line-through;
    margin-bottom: 6px;
  }
  .cart .main .tabel .tabelmain .price .newprice {
    color: #000000;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .cart .main .tabel .tabelmain .price .mon {
    color: #000000;
    margin-bottom: 17px;
    font-size: 12px;
  }
  .cart .main .tabel .tabelmain .price .deposit {
    margin-bottom: 17px;
    font-size: 14px;
    color: #28a6d4;
  }
  .cart .main .tabel .tabelmain .price .del {
    color: #000000;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
  }
  .cart .main .tabel .tabelbot {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #000;
  }
  .cart .main .tabel .tabelbot .left {
    display: flex;
  }
  .cart .main .tabel .tabelbot .left .cart-subscription-disclaimer {
    width: 366px;
    font-size: 10px;
    line-height: 13px;
  }
  .cart .main .tabel .tabelbot .left .text {
    font-size: 13px;
    color: #ff8800;
  }
  .cart .main .tabel .tabelbot .left img {
    width: 96px;
    height: 32px;
    margin-left: 10px;
  }
  .cart .main .tabel .tabelbot .right p {
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-size: 13px;
    line-height: 20px;
  }
  .cart .main .tabel .tabelbot .right p.p1 {
    font-size: 14px;
  }
  .cart .main .tabel .tabelbot .right p.p1 span {
    font-size: 14px;
  }
  .cart .main .tabel .tabelbot .right p.p1 i {
    font-size: 12px;
  }
  .cart .main .tabel .tabelbot .right p.p2 {
    font-size: 12px;
    margin-top: 18px;
    border-top: 1px solid #000;
    padding-top: 18px;
  }
  .cart .main .tabel .tabelbot .right .btn {
    width: 196px;
    height: 37px;
    background: #f8e71c;
    font-size: 12px;
    color: #000;
    line-height: 37px;
    text-align: center;
    padding: 0;
    float: right;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .main .tabel .tabelbot .right .btn2 {
    width: 196px;
    height: 37px;
    background: #fff;
    border: 1px solid #000;
    font-size: 12px;
    color: #000;
    line-height: 37px;
    text-align: center;
    padding: 0;
    float: right;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
  }
  .cart .main .tabel .tabelbot .right .continue-shopping-link {
    width: 196px;
    float: right;
    margin-top: 25px;
    display: block;
    text-align: center;
    text-decoration: underline;
    text-transform: capitalize;
    color: #000;
  }
}

@media screen and (max-width: 1024px) {
  .cart .topheader {
    display: none;
    padding-top: 38px;
  }
  .cart .topheader .li {
    margin-right: 25px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 7px;
    width: 18px;
    height: 18px;
    line-height: 16px;
    font-size: 12px;
  }
  .cart .topheader .li i {
    color: #000000;
    font-size: 12px;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .main {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cart .main .title {
    color: #000;
    margin-top: 25px;
    margin-bottom: 13px;
    font-size: 30px;
  }
  .cart .main .tabel .continue-shopping-link {
    font-size: 12px;
  }
  .cart .main .tabel .tabelheader {
    display: none;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelheader .desc {
    flex: 1;
    font-size: 12px;
  }
  .cart .main .tabel .tabelheader .qty {
    width: 187px;
    font-size: 12px;
  }
  .cart .main .tabel .tabelheader .price {
    width: 92px;
    font-size: 12px;
  }
  .cart .main .tabel .tabelmain {
    display: block;
    border-top: 1px solid #000;
    padding: 21px 0;
  }
  .cart .main .tabel .tabelmain .shipping-delivery-date {
    font-size: 12px;
    margin-top: 15px;
  }
  .cart .main .tabel .tabelmain .cart-checkmark {
    width: 10px;
    height: 10px;
    margin-top: -3px;
  }
  .cart .main .tabel .tabelmain p {
    font-size: 12px;
  }
  .cart .main .tabel .tabelmain .desc {
    flex: 1;
    display: block;
    margin-bottom: 15px;
  }
  .cart .main .tabel .tabelmain .desc .img {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .cart .main .tabel .tabelmain .desc .img img {
    width: 358px;
    height: 199px;
    display: block;
  }
  .cart .main .tabel .tabelmain .desc .desctext {
    display: block;
    flex-direction: column;
    justify-content: space-between;
  }
  .cart .main .tabel .tabelmain .desc .desctext .shipping-notice {
    font-size: 12px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 img {
    margin-top: 8px;
    margin-bottom: 3px;
    width: 96px;
    height: 36px;
    margin-right: 60px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 p {
    font-size: 13px;
    color: #ff8800;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .build2 u {
    margin-top: 13px;
    display: block;
    cursor: pointer;
    font-size: 12px;
    color: #777;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .name-series {
    font-size: 14px;
    color: #000;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .name {
    font-size: 20px;
    color: #000;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .desc {
    font-size: 13px;
    margin-top: 17px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .p {
    font-size: 13px;
    color: #9b9b9b;
    margin-top: 17px;
    width: 162px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .plaque-name {
    font-size: 14px;
    color: #000;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .toptext .plaque-creditline {
    font-size: 14px;
    color: #000;
    margin-bottom: 8px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 {
    display: flex;
    margin-top: 15px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 .text {
    color: #ff8800;
    font-size: 13px;
    margin-left: 8px;
    padding-top: 4px;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 .text p {
    color: #ff8800;
    font-size: 13px;
    line-height: 17px;
  }
  .cart .main .tabel .tabelmain .desc .desctext .build1 img {
    width: 96px;
    height: 36px;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .qty {
    width: 162px;
    display: flex;
    margin-bottom: 15px;
  }
  .cart .main .tabel .tabelmain .qty span {
    width: 33px;
    height: 33px;
    border: 1px solid #4a4a4a;
    line-height: 33px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
  }
  .cart .main .tabel .tabelmain .qty input {
    width: 33px;
    height: 33px;
    border-top: 1px solid #4a4a4a;
    border-bottom: 1px solid #4a4a4a;
    font-size: 13px;
    color: #4a4a4a;
    padding-left: 0;
    text-align: center;
  }
  .cart .main .tabel .tabelmain .qty input::-webkit-outer-spin-button,
  .cart .main .tabel .tabelmain .qty input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .cart .main .tabel .tabelmain .qty-fixed {
    font-size: 14px;
    display: none !important;
  }
  .cart .main .tabel .tabelmain .price {
    width: auto;
    text-align: left;
  }
  .cart .main .tabel .tabelmain .price .oldprice {
    color: #000000;
    font-size: 14px;
    text-decoration: line-through;
    margin-bottom: 6px;
  }
  .cart .main .tabel .tabelmain .price .newprice {
    color: #000000;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .cart .main .tabel .tabelmain .price .mon {
    color: #000000;
    margin-bottom: 17px;
    font-size: 12px;
  }
  .cart .main .tabel .tabelmain .price .deposit {
    margin-bottom: 17px;
    font-size: 14px;
    color: #28a6d4;
  }
  .cart .main .tabel .tabelmain .price .del {
    color: #000000;
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
  }
  .cart .main .tabel .tabelbot {
    display: block;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #000;
  }
  .cart .main .tabel .tabelbot .left {
    display: flex;
    margin-bottom: 20px;
  }
  .cart .main .tabel .tabelbot .left .cart-subscription-disclaimer {
    width: 366px;
    font-size: 10px;
    line-height: 13px;
  }
  .cart .main .tabel .tabelbot .left .text {
    font-size: 13px;
    color: #ff8800;
  }
  .cart .main .tabel .tabelbot .left img {
    width: 96px;
    height: 32px;
    margin-left: 10px;
  }
  .cart .main .tabel .tabelbot .right p {
    display: flex;
    max-width: 375px;
    justify-content: space-between;
    color: #000000;
    font-size: 13px;
    line-height: 20px;
  }
  .cart .main .tabel .tabelbot .right p.p1 {
    font-size: 14px;
  }
  .cart .main .tabel .tabelbot .right p.p1 span {
    font-size: 14px;
  }
  .cart .main .tabel .tabelbot .right p.p1 i {
    font-size: 12px;
  }
  .cart .main .tabel .tabelbot .right p.p2 {
    font-size: 12px;
    margin-top: 18px;
    border-top: 1px solid #000;
    padding-top: 18px;
  }
  .cart .main .tabel .tabelbot .right .btn {
    width: 196px;
    height: 37px;
    background: #f8e71c;
    font-size: 12px;
    color: #000;
    line-height: 37px;
    text-align: center;
    padding: 0;
    float: none;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .main .tabel .tabelbot .right .btn2 {
    width: 196px;
    height: 37px;
    background: #fff;
    border: 1px solid #000;
    font-size: 12px;
    color: #000;
    line-height: 37px;
    text-align: center;
    padding: 0;
    float: none;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
  }
  .cart .main .tabel .tabelbot .right .continue-shopping-link {
    float: none;
    margin-top: 25px;
    display: block;
    text-align: left;
    text-decoration: underline;
    text-transform: capitalize;
    color: #000;
  }
}

.mobilecart .pop {
  width: 90%;
  /*height: 250px;*/
  top: 50%;
  margin-top: -125px;
  left: 50%;
  margin-left: -45%;
  padding: 20px;
}

.mobilecart .pop h3 {
  margin-top: 5px;
  font-size: 24px;
}

.mobilecart .pop .btn {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 120px;
  cursor: pointer;
}

.mobilecart .pop .btn-confirm {
  background-color: #000;
  color: #fff;
}

.mobilecart .pop .btn-confirm:hover {
  background-color: #fff;
  color: #000;
}

.mobilecart .pop .btn-cancel {
  background-color: #fff;
  color: #000;
}

.mobilecart .pop .btn-cancel:hover {
  background-color: #000;
  color: #fff;
}

.mobilecart .pop .close {
  right: 20px;
  top: 20px;
}

.btn-discount-code {
  background: #f8e71c;
  font-size: 14px;
  color: #000;
  line-height: 30px;
  text-align: center;
  padding: 0 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #f8e71c;
}

.discount-code-container {
  margin-bottom: 20px;
}

.discount-code-error {
  color: red;
  margin-bottom: 20px;
}

.discount-code-container input {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  padding-left: 0;
  font-size: 16px;
  flex: 1 1;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

.pts-long p {
  position: relative;
  padding-left: 20px;
  line-height: 17px;
  margin-bottom: 8px;
}

.pts-long p img {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 0px !important;
}

.shipping-delivery-date-mobile {
  margin-top: 15px;
}

.shipping-notice-mobile {
  margin-top: 15px;
}

.empty-cart-wrapper-mobile {
  text-align: center;
  padding: 50px;
}

.empty-cart-image-mobile {
  width: 128px;
  height: 128px;
}

.empty-cart-heading-mobile {
  margin-top: 25px;
  font-size: 28px;
  font-weight: bold;
}

.empty-cart-p-mobile {
  margin-top: 20px;
  font-size: 16px;
}

.empty-cart-p2-mobile {
  margin-top: 5px;
  font-size: 16px;
}
.empty-cart-p2-mobile a {
  color: #000;
  text-decoration: underline;
}

.mobilecart .topheader {
  display: flex;
  justify-content: space-between;
}
.mobilecart .topheader .title {
  font-size: 1.714286rem;
  width: 66%;
  flex-shrink: 0;
}
.mobilecart .topheader .ul {
  display: flex;
}
.mobilecart .topheader .ul .li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobilecart .topheader .ul .li span {
  width: 1.428571rem;
  height: 1.428571rem;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #000000;
  text-align: center;
  line-height: 1.428571rem;
}
.mobilecart .topheader .ul .li i {
  width: 1rem;
  height: 0.214286rem;
  background: #000;
  display: inline-block;
  vertical-align: middle;
}
.mobilecart .topheader .ul .li.active span {
  background: #000;
  color: white;
}

.mobilecart .cart-item {
  padding-bottom: 30px;
  border-bottom: 1px solid #000;
}

.mobilecart .tabelmain {
  padding-bottom: 0.714286rem;
}
.mobilecart .tabelmain .desc img {
  width: 100%;
  margin-top: 1.071429rem;
  margin-bottom: 1.071429rem;
}
.mobilecart .tabelmain .desc .desctext .build2 img {
  width: 6.928571rem;
  height: 2.595714rem;
  margin-top: 1.071429rem;
  margin-bottom: 0.571429rem;
}
.mobilecart .tabelmain .desc .desctext .build2 .p {
  display: flex;
  justify-content: space-between;
}
.mobilecart .tabelmain .desc .desctext .build2 .p p {
  color: #ff8800;
  flex: 1;
  line-height: 16px;
}
.mobilecart .tabelmain .desc .desctext .build2 .p span {
  font-size: 0.857143rem;
  width: 9.214286rem;
  display: block;
  margin-top: 1.714286rem;
  text-decoration: underline;
  text-align: right;
  color: #777;
}
.mobilecart .tabelmain .desc .desctext .name-series {
  font-size: 16px;
  margin-bottom: 10px;
}
.mobilecart .tabelmain .desc .desctext .name {
  font-size: 20px;
}
.mobilecart .tabelmain .desc .desctext .desc {
  font-size: 1rem;
  margin-top: 0.571429rem;
}
.mobilecart .tabelmain .desc .desctext .plaque-name {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mobilecart .tabelmain .desc .desctext .plaque-creditline {
  font-size: 16px;
  margin-bottom: 10px;
}
.mobilecart .tabelmain .numandprice {
  display: flex;
  justify-content: space-between;
  margin-top: 1.071429rem;
}
.mobilecart .tabelmain .numandprice .qty {
  width: 8.928571rem;
  display: flex;
}
.mobilecart .tabelmain .numandprice .qty span {
  width: 2.857143rem;
  height: 2.857143rem;
  border: 1px solid #4a4a4a;
  line-height: 2.857143rem;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.mobilecart .tabelmain .numandprice .qty input {
  width: 2.857143rem;
  height: 2.857143rem;
  border-top: 1px solid #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
  font-size: 1.142857rem;
  color: #4a4a4a;
  padding-left: 0;
  text-align: center;
}
.mobilecart .tabelmain .numandprice .qty input::-webkit-outer-spin-button,
.mobilecart .tabelmain .numandprice .qty input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.mobilecart .tabelmain .numandprice .price .prices {
  display: block;
  width: 14.714286rem;
  justify-content: flex-end;
  font-size: 13px;
  text-align: right;
}
.mobilecart .tabelmain .numandprice .price .prices .oldprice {
  color: #000000;
  /*font-size: 1rem;*/
  text-decoration: line-through;
}
.mobilecart .tabelmain .numandprice .price .prices .newprice {
  color: #000000;
  margin-left: 0.714286rem;
}
.mobilecart .tabelmain .numandprice .price .del {
  color: #000000;
  margin-top: 1rem;
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
}

.mobilecart .tabelbot .left {
  display: flex;
  padding: 0.714286rem 0;
  margin: 0;
  justify-content: space-between;
}
.mobilecart .tabelbot .left .text {
  color: #ff8800;
  width: 15.214286rem;
  padding-top: 5px;
}
.mobilecart .tabelbot .left img {
  width: 7.254286rem;
  height: 2.714286rem;
}

.mobilecart .bto_right {
  margin-top: 1.428571rem;
}
.mobilecart .bto_right p {
  display: flex;
  justify-content: space-between;
  color: #000000;
  font-size: 1.142857rem;
}
.mobilecart .bto_right p.p2 {
  font-size: 1rem;
  margin-top: 1.571429rem;
}
.mobilecart .bto_right .btn {
  width: 100%;
  height: 3.214286rem;
  background: #f8e71c;
  font-size: 1rem;
  color: #000;
  line-height: 3.214286rem;
  text-align: center;
  padding: 0;
  margin-top: 1.714286rem;
  margin-bottom: 1.428571rem;
  font-weight: bold;
}
.mobilecart .bto_right .btn2 {
  width: 100%;
  height: 3.214286rem;
  background: #fff;
  border: 1px solid #000;
  font-size: 1rem;
  color: #000;
  line-height: 3.214286rem;
  text-align: center;
  padding: 0;
  margin-top: 1.714286rem;
  margin-bottom: 1.428571rem;
}
.mobilecart .bto_right .continue-shopping-link {
  margin-top: 30px;
  display: block;
  text-align: center;
  text-decoration: underline;
  text-transform: capitalize;
  color: #000;
}

/* Cart2 */
.form-error {
  color: red;
  display: none;
}

.form-error-email {
  color: red;
  display: none;
}

.form-error-text {
  color: red;
}

.form-error-placeholder::placeholder {
  color: red;
}

.payment-disclaimer {
  margin-top: 20px;
}

.cart .cart2 .main .left {
  width: 50%;
}

.payment-preloader-container {
  display: none;
  width: 236px;
  height: 45px;
  float: right;
  margin-bottom: 20px;
  margin-top: 20px;
}

.payment-preloader {
  width: 45px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
}
.payment-preloader img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 2000px) {
  .cart .topheader {
    display: flex;
    padding-top: 45px;
  }
  .cart .topheader .li {
    margin-right: 30px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 8px;
    height: 22px;
    line-height: 20px;
    font-size: 14px;
  }
  .cart .topheader .li i {
    color: #000000;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .cart2 .main {
    display: flex;
    justify-content: space-between;
  }
  .cart .cart2 .main .left .title {
    font-weight: 350;
  }
  .cart .cart2 .main .left .form .group {
    position: relative;
    display: flex;
    margin-top: 20px;
  }
  .cart .cart2 .main .left .form .group input {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 0;
    font-size: 16px;
  }
  .cart .cart2 .main .left .form .group input + input {
    margin-left: 10px;
  }
  .cart .cart2 .main .left .form .group input::-webkit-input-placeholder,
  .cart .cart2 .main .left .form .group input::-moz-placeholder,
  .cart .cart2 .main .left .form .group input:-ms-input-placeholder,
  .cart .cart2 .main .left .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .cart .cart2 .main .left .form .group .chooseCountry {
    position: absolute;
    bottom: -92px;
    right: 0;
    width: 216px;
    border: 1px solid #000;
  }
  .cart .cart2 .main .left .form .group .chooseCountry div {
    position: relative;
    z-index: 11;
    padding-left: 18px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    color: #000;
    background-color: #fff;
  }
  .cart .cart2 .main .left .form .group .chooseCountry div:hover {
    background-color: #d8d8d8;
  }
  .cart .cart2 .main .left .form p {
    font-size: 12px;
    margin-top: 12px;
    color: #000;
  }
  .cart .cart2 .main .left .form .disclaimer {
    font-size: 10px;
    margin-top: -2px;
  }
  .cart .cart2 .main .left .checkbox {
    margin: 20px 0;
  }
  .cart .cart2 .main .left .checkbox label {
    padding-left: 0px;
  }
  .cart .cart2 .main .left .btn {
    width: 236px;
    height: 45px;
    background: #f8e71c;
    line-height: 45px;
    text-align: center;
    float: right;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .cart2 .main .right .li {
    margin-top: 5px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #000;
  }
  .cart .cart2 .main .right .title {
    font-size: 14px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
  }
  .cart .cart2 .main .right .bigimg {
    width: 197px;
    height: 112px;
  }
  .cart .cart2 .main .right .name-series {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cart .cart2 .main .right .name {
    font-size: 20px;
  }
  .cart .cart2 .main .right .desc {
    margin-top: 10px;
  }
  .cart .cart2 .main .right .qty {
    font-size: 14px;
    margin-top: 10px;
  }
  .cart .cart2 .main .right .smalimg {
    width: 106px;
    height: 40px;
    margin-top: 10px;
  }
  .cart .cart2 .main .right .price {
    color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 2000px) {
  .payment-preloader-container {
    display: none;
    width: 315px;
    height: 60px;
    float: right;
    margin-bottom: 27px;
    margin-top: 27px;
  }
  .payment-preloader {
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
  }
  .payment-preloader img {
    width: 100%;
    height: 100%;
  }
  .form-error {
    font-size: 21px;
  }
  .form-error-email {
    font-size: 21px;
  }
  .cart .topheader {
    display: flex;
    padding-top: 60px;
  }
  .cart .topheader .li {
    margin-right: 40px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 11px;
    width: 30px;
    height: 30px;
    line-height: 26px;
    font-size: 19px;
  }
  .cart .topheader .li i {
    color: #000000;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .cart2 .main {
    display: flex;
    justify-content: space-between;
  }
  .cart .cart2 .main .left .payment-disclaimer {
    font-size: 19px;
  }
  .cart .cart2 .main .left .title {
    font-weight: 350;
  }
  .cart .cart2 .main .left .form .group {
    position: relative;
    display: flex;
    margin-top: 27px;
  }
  .cart .cart2 .main .left .form .group input {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 13px;
    padding-left: 0;
    font-size: 21px;
  }
  .cart .cart2 .main .left .form .group input + input {
    margin-left: 13px;
    font-size: 21px;
  }
  .cart .cart2 .main .left .form .group input::-webkit-input-placeholder,
  .cart .cart2 .main .left .form .group input::-moz-placeholder,
  .cart .cart2 .main .left .form .group input:-ms-input-placeholder,
  .cart .cart2 .main .left .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .cart .cart2 .main .left .form .group .chooseCountry {
    position: absolute;
    bottom: -123px;
    right: 0;
    width: 288px;
    border: 1px solid #000;
  }
  .cart .cart2 .main .left .form .group .chooseCountry div {
    position: relative;
    z-index: 11;
    padding-left: 24px;
    height: 59px;
    line-height: 59px;
    font-size: 19px;
    color: #000;
    background-color: #fff;
  }
  .cart .cart2 .main .left .form .group .chooseCountry div:hover {
    background-color: #d8d8d8;
  }
  .cart .cart2 .main .left .form p {
    font-size: 16px;
    margin-top: 16px;
    color: #000;
  }
  .cart .cart2 .main .left .form .disclaimer {
    font-size: 13px;
    margin-top: 2px;
  }
  .cart .cart2 .main .left .checkbox {
    margin: 27px 0;
  }
  .cart .cart2 .main .left .checkbox label {
    padding-left: 0px;
  }
  .cart .cart2 .main .left .btn {
    width: 315px;
    height: 60px;
    background: #f8e71c;
    line-height: 60px;
    text-align: center;
    float: right;
    padding: 0;
    margin-bottom: 27px;
    margin-top: 27px;
    cursor: pointer;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .cart2 .main .right .li {
    margin-top: 7px;
    margin-bottom: 27px;
    padding-bottom: 27px;
    border-bottom: 1px solid #000;
  }
  .cart .cart2 .main .right .li .mon {
    font-size: 19px;
  }
  .cart .cart2 .main .right .title {
    font-size: 19px;
    border-bottom: 1px solid #000;
    padding-bottom: 13px;
  }
  .cart .cart2 .main .right .bigimg {
    width: 263px;
    height: 149px;
  }
  .cart .cart2 .main .right .name-series {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cart .cart2 .main .right .name {
    font-size: 27px;
  }
  .cart .cart2 .main .right .desc {
    margin-top: 10px;
  }
  .cart .cart2 .main .right .qty {
    font-size: 19px;
    margin-top: 13px;
  }
  .cart .cart2 .main .right .smalimg {
    width: 141px;
    height: 53px;
    margin-top: 13px;
  }
  .cart .cart2 .main .right .price {
    color: #000;
    margin-top: 13px;
    font-size: 19px;
    margin-bottom: 13px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1250px) {
  .payment-preloader-container {
    display: none;
    width: 197px;
    height: 37px;
    float: right;
    margin-bottom: 17px;
    margin-top: 17px;
  }
  .payment-preloader {
    width: 37px;
    height: 37px;
    margin-left: auto;
    margin-right: auto;
  }
  .payment-preloader img {
    width: 100%;
    height: 100%;
  }
  .cart .topheader {
    display: flex;
    padding-top: 38px;
  }
  .cart .topheader .li {
    margin-right: 25px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 7px;
    width: 18px;
    height: 18px;
    line-height: 16px;
    font-size: 12px;
  }
  .cart .topheader .li i {
    color: #000000;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .cart2 .main {
    display: flex;
    justify-content: space-between;
  }
  .cart .cart2 .main .left .payment-disclaimer {
    font-size: 12px;
  }
  .cart .cart2 .main .left .title {
    font-weight: 350;
  }
  .cart .cart2 .main .left .form .group {
    position: relative;
    display: flex;
    margin-top: 17px;
  }
  .cart .cart2 .main .left .form .group input {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 8px;
    padding-left: 0;
    font-size: 14px;
  }
  .cart .cart2 .main .left .form .group input + input {
    margin-left: 8px;
    font-size: 14px;
  }
  .cart .cart2 .main .left .form .group input::-webkit-input-placeholder,
  .cart .cart2 .main .left .form .group input::-moz-placeholder,
  .cart .cart2 .main .left .form .group input:-ms-input-placeholder,
  .cart .cart2 .main .left .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .cart .cart2 .main .left .form .group .chooseCountry {
    position: absolute;
    bottom: -77px;
    right: 0;
    width: 180px;
    border: 1px solid #000;
  }
  .cart .cart2 .main .left .form .group .chooseCountry div {
    position: relative;
    z-index: 11;
    padding-left: 15px;
    height: 37px;
    line-height: 37px;
    font-size: 12px;
    color: #000;
    background-color: #fff;
  }
  .cart .cart2 .main .left .form .group .chooseCountry div:hover {
    background-color: #d8d8d8;
  }
  .cart .cart2 .main .left .form p {
    font-size: 10px;
    margin-top: 10px;
    color: #000;
  }
  .cart .cart2 .main .left .checkbox {
    margin: 17px 0;
  }
  .cart .cart2 .main .left .checkbox label {
    padding-left: 0px;
  }
  .cart .cart2 .main .left .btn {
    width: 197px;
    height: 37px;
    background: #f8e71c;
    line-height: 37px;
    text-align: center;
    float: right;
    padding: 0;
    margin-bottom: 17px;
    margin-top: 17px;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .cart2 .main .right .li {
    margin-top: 4px;
    margin-bottom: 17px;
    padding-bottom: 17px;
    border-bottom: 1px solid #000;
  }
  .cart .cart2 .main .right .li .mon {
    font-size: 12px;
  }
  .cart .cart2 .main .right .title {
    font-size: 12px;
    border-bottom: 1px solid #000;
    padding-bottom: 8px;
  }
  .cart .cart2 .main .right .bigimg {
    width: 164px;
    height: 93px;
  }
  .cart .cart2 .main .right .name-series {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cart .cart2 .main .right .name {
    font-size: 17px;
  }
  .cart .cart2 .main .right .desc {
    margin-top: 10px;
  }
  .cart .cart2 .main .right .qty {
    font-size: 12px;
    margin-top: 8px;
  }
  .cart .cart2 .main .right .smalimg {
    width: 88px;
    height: 33px;
    margin-top: 8px;
  }
  .cart .cart2 .main .right .price {
    color: #000;
    margin-top: 8px;
    font-size: 12px;
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .payment-preloader-container {
    display: none;
    width: 197px;
    height: 37px;
    float: right;
    margin-bottom: 17px;
    margin-top: 17px;
  }
  .payment-preloader {
    width: 37px;
    height: 37px;
    margin-left: auto;
    margin-right: auto;
  }
  .payment-preloader img {
    width: 100%;
    height: 100%;
  }
  .cart .topheader {
    display: none;
    padding-top: 38px;
  }
  .cart .topheader .li {
    margin-right: 25px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 7px;
    width: 18px;
    height: 18px;
    line-height: 16px;
    font-size: 12px;
  }
  .cart .topheader .li i {
    color: #000000;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .cart2 .main {
    display: flex;
    justify-content: space-between;
  }
  .cart .cart2 .main .left {
    width: auto;
  }
  .cart .cart2 .main .left .payment-disclaimer {
    font-size: 12px;
  }
  .cart .cart2 .main .left .title {
    font-weight: 350;
  }
  .cart .cart2 .main .left .form .group {
    position: relative;
    display: flex;
    margin-top: 17px;
  }
  .cart .cart2 .main .left .form .group input {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 8px;
    padding-left: 0;
    font-size: 14px;
  }
  .cart .cart2 .main .left .form .group input + input {
    margin-left: 8px;
    font-size: 14px;
  }
  .cart .cart2 .main .left .form .group input::-webkit-input-placeholder,
  .cart .cart2 .main .left .form .group input::-moz-placeholder,
  .cart .cart2 .main .left .form .group input:-ms-input-placeholder,
  .cart .cart2 .main .left .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .cart .cart2 .main .left .form .group .chooseCountry {
    position: absolute;
    bottom: -77px;
    right: 0;
    width: 180px;
    border: 1px solid #000;
  }
  .cart .cart2 .main .left .form .group .chooseCountry div {
    position: relative;
    z-index: 11;
    padding-left: 15px;
    height: 37px;
    line-height: 37px;
    font-size: 12px;
    color: #000;
    background-color: #fff;
  }
  .cart .cart2 .main .left .form .group .chooseCountry div:hover {
    background-color: #d8d8d8;
  }
  .cart .cart2 .main .left .form p {
    font-size: 10px;
    margin-top: 10px;
    color: #000;
  }
  .cart .cart2 .main .left .checkbox {
    margin: 17px 0;
  }
  .cart .cart2 .main .left .checkbox label {
    padding-left: 0px;
  }
  .cart .cart2 .main .left .btn {
    width: 197px;
    height: 37px;
    background: #f8e71c;
    line-height: 37px;
    text-align: center;
    float: right;
    padding: 0;
    margin-bottom: 17px;
    margin-top: 17px;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .cart2 .main .right {
    display: none;
  }
  .cart .cart2 .main .right .li {
    margin-top: 4px;
    margin-bottom: 17px;
    padding-bottom: 17px;
    border-bottom: 1px solid #000;
  }
  .cart .cart2 .main .right .li .mon {
    font-size: 12px;
  }
  .cart .cart2 .main .right .title {
    font-size: 12px;
    border-bottom: 1px solid #000;
    padding-bottom: 8px;
  }
  .cart .cart2 .main .right .bigimg {
    width: 164px;
    height: 93px;
  }
  .cart .cart2 .main .right .name-series {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .cart .cart2 .main .right .name {
    font-size: 17px;
  }
  .cart .cart2 .main .right .desc {
    margin-top: 10px;
  }
  .cart .cart2 .main .right .qty {
    font-size: 12px;
    margin-top: 8px;
  }
  .cart .cart2 .main .right .smalimg {
    width: 88px;
    height: 33px;
    margin-top: 8px;
  }
  .cart .cart2 .main .right .price {
    color: #000;
    margin-top: 8px;
    font-size: 12px;
    margin-bottom: 8px;
  }
}

.mobilecart .topheader {
  display: flex;
  justify-content: space-between;
}
.mobilecart .topheader .title {
  font-size: 1.714286rem;
  width: 66%;
  flex-shrink: 0;
}
.mobilecart .topheader .ul {
  display: flex;
}
.mobilecart .topheader .ul .li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobilecart .topheader .ul .li span {
  width: 1.428571rem;
  height: 1.428571rem;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #000000;
  text-align: center;
  line-height: 1.428571rem;
}
.mobilecart .topheader .ul .li i {
  width: 1rem;
  height: 0.214286rem;
  background: #000;
  display: inline-block;
  vertical-align: middle;
}
.mobilecart .topheader .ul .li.active span {
  background: #000;
  color: white;
}

.mobilecart .cart2 .main {
  display: flex;
  flex-direction: column;
}
.mobilecart .cart2 .main .left .title {
  font-weight: 350;
  margin-top: 1.285714rem;
}
.mobilecart .cart2 .main .left .form .group {
  position: relative;
  display: flex;
  margin-top: 1.428571rem;
}
.mobilecart .cart2 .main .left .form .group input {
  flex: 1;
  width: 100%;
  border-bottom: 1px solid #000;
  padding-bottom: 0.714286rem;
  padding-left: 0;
}
.mobilecart .cart2 .main .left .form .group input + input {
  margin-left: 0.714286rem;
}
.mobilecart .cart2 .main .left .form .group input::-webkit-input-placeholder,
.mobilecart .cart2 .main .left .form .group input::-moz-placeholder,
.mobilecart .cart2 .main .left .form .group input:-ms-input-placeholder,
.mobilecart .cart2 .main .left .form .group input:-moz-placeholder {
  color: #9b9b9b;
}
.mobilecart .cart2 .main .left .form .group .chooseCountry {
  position: absolute;
  bottom: -92px;
  right: 0;
  width: 216px;
  border: 1px solid #000;
}
.mobilecart .cart2 .main .left .form .group .chooseCountry div {
  position: relative;
  z-index: 11;
  padding-left: 18px;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
}
.mobilecart .cart2 .main .left .form .group .chooseCountry div:hover {
  background-color: #d8d8d8;
}
.mobilecart .cart2 .main .left .form p {
  font-size: 0.857143rem;
  margin-top: 0.857143rem;
  color: #000;
}
.mobilecart .cart2 .main .left .form .disclaimer {
  font-size: 10px;
  margin-top: 1px;
  line-height: 12px;
}
.mobilecart .cart2 .main .left .checkbox {
  margin: 1.428571rem 0;
}
.mobilecart .cart2 .main .left .checkbox label {
  padding-left: 0px;
}
.mobilecart .cart2 .main .left .btn {
  width: 100%;
  height: 3.214286rem;
  background: #f8e71c;
  line-height: 3.214286rem;
  text-align: center;
  float: right;
  padding: 0;
  margin-bottom: 1.428571rem;
  margin-top: 1.428571rem;
}
.mobilecart .cart2 .main .left .payment-preloader-container {
  display: none;
  width: 100%;
  height: 3.214286rem;
  float: right;
  margin-bottom: 1.428571rem;
  margin-top: 1.428571rem;
}
.mobilecart .cart2 .main .left .payment-preloader-container .payment-preloader {
  width: 3.214286rem;
  height: 3.214286rem;
  margin-left: auto;
  margin-right: auto;
}
.mobilecart
  .cart2
  .main
  .left
  .payment-preloader-container
  .payment-preloader
  img {
  width: 100%;
  height: 100%;
}
.mobilecart .cart2 .main .left .payment-disclaimer {
  line-height: 16px;
}

/* Cart3 */
@media screen and (max-width: 2000px) {
  .cart .topheader {
    display: flex;
    padding-top: 45px;
  }
  .cart .topheader .li {
    margin-right: 30px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 8px;
    width: 22px;
    height: 22px;
    line-height: 20px;
    font-size: 14px;
  }
  .cart .topheader .li i {
    color: #000000;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .cart3 .main {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  .cart .cart3 .main .left .title {
    font-weight: 350;
  }
  .cart .cart3 .main .left .subsequent-section {
    margin-top: 50px;
  }
  .cart .cart3 .main .left .form .group {
    display: flex;
    margin-top: 20px;
  }
  .cart .cart3 .main .left .form .group input {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 0;
  }
  .cart .cart3 .main .left .form .group input + input {
    margin-left: 10px;
  }
  .cart .cart3 .main .left .form .group input::-webkit-input-placeholder,
  .cart .cart3 .main .left .form .group input::-moz-placeholder,
  .cart .cart3 .main .left .form .group input:-ms-input-placeholder,
  .cart .cart3 .main .left .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .cart .cart3 .main .left .form p {
    font-size: 12px;
    margin-top: 12px;
    color: #000;
  }
  .cart .cart3 .main .left .checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .cart .cart3 .main .left .checkbox label {
    padding-left: 0px;
  }
  .cart .cart3 .main .left p {
    width: 468px;
  }
  .cart .cart3 .main .left .tit {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .cart .cart3 .main .left .group {
    display: flex;
    margin-top: 20px;
  }
  .cart .cart3 .main .left .group input {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
    padding-left: 0;
  }
  .cart .cart3 .main .left .group input + input {
    margin-left: 10px;
  }
  .cart .cart3 .main .left .group input::-webkit-input-placeholder,
  .cart .cart3 .main .left .group input::-moz-placeholder,
  .cart .cart3 .main .left .group input:-ms-input-placeholder,
  .cart .cart3 .main .left .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .cart .cart3 .main .left .topbtn {
    width: 236px;
    height: 45px;
    border: 2px solid #1a1a1a;
    line-height: 43px;
    text-align: center;
    margin-top: 55px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .cart3 .main .left .btn {
    width: 236px;
    height: 45px;
    background: #f8e71c;
    line-height: 45px;
    text-align: center;
    float: none;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .cart3 .main .right .title {
    font-size: 14px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
  }
  .cart .cart3 .main .right .bigimg {
    width: 197px;
    height: 112px;
  }
  .cart .cart3 .main .right .name {
    font-size: 20px;
    margin-top: 29px;
    width: 322px;
  }
  .cart .cart3 .main .right .qty {
    font-size: 14px;
    margin-top: 10px;
  }
  .cart .cart3 .main .right .smalimg {
    width: 106px;
    height: 40px;
    margin-top: 10px;
  }
  .cart .cart3 .main .right .price {
    color: #ff8800;
    margin-top: 10px;
  }
}

@media screen and (min-width: 2000px) {
  .cart .topheader {
    display: flex;
    padding-top: 60px;
  }
  .cart .topheader .li {
    margin-right: 40px;
  }
  .cart .topheader .li span {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid #000000;
    text-align: center;
    margin-right: 11px;
    width: 30px;
    height: 30px;
    line-height: 26px;
    font-size: 19px;
  }
  .cart .topheader .li i {
    color: #000000;
  }
  .cart .topheader .li.active span {
    background: #000;
    color: white;
  }
  .cart .cart3 .main {
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
  }
  .cart .cart3 .main .left .title {
    font-weight: 350;
  }
  .cart .cart3 .main .left .subsequent-section {
    margin-top: 67px;
  }
  .cart .cart3 .main .left .form .group {
    display: flex;
    margin-top: 27px;
  }
  .cart .cart3 .main .left .form .group input {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 13px;
    padding-left: 0;
  }
  .cart .cart3 .main .left .form .group input + input {
    margin-left: 13px;
  }
  .cart .cart3 .main .left .form .group input::-webkit-input-placeholder,
  .cart .cart3 .main .left .form .group input::-moz-placeholder,
  .cart .cart3 .main .left .form .group input:-ms-input-placeholder,
  .cart .cart3 .main .left .form .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .cart .cart3 .main .left .form p {
    font-size: 16px;
    margin-top: 16px;
    color: #000;
  }
  .cart .cart3 .main .left .checkbox {
    margin-top: 27px;
    margin-bottom: 27px;
  }
  .cart .cart3 .main .left .checkbox label {
    padding-left: 0px;
  }
  .cart .cart3 .main .left p {
    width: 624px;
    font-size: 19px;
  }
  .cart .cart3 .main .left .tit {
    margin-top: 27px;
    margin-bottom: 13px;
    font-size: 19px;
  }
  .cart .cart3 .main .left .group {
    display: flex;
    margin-top: 27px;
  }
  .cart .cart3 .main .left .group input {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 13px;
    padding-left: 0;
    font-size: 19px;
  }
  .cart .cart3 .main .left .group input + input {
    margin-left: 13px;
  }
  .cart .cart3 .main .left .group input::-webkit-input-placeholder,
  .cart .cart3 .main .left .group input::-moz-placeholder,
  .cart .cart3 .main .left .group input:-ms-input-placeholder,
  .cart .cart3 .main .left .group input:-moz-placeholder {
    color: #9b9b9b;
  }
  .cart .cart3 .main .left .topbtn {
    width: 315px;
    height: 60px;
    border: 2px solid #1a1a1a;
    line-height: 60px;
    text-align: center;
    margin-top: 73px;
    cursor: pointer;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .cart3 .main .left .btn {
    width: 315px;
    height: 60px;
    background: #f8e71c;
    line-height: 60px;
    text-align: center;
    float: none;
    padding: 0;
    margin-bottom: 27px;
    margin-top: 27px;
    cursor: pointer;
    font-size: 19px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .cart .cart3 .main .right .title {
    font-size: 19px;
    border-bottom: 1px solid #000;
    padding-bottom: 13px;
  }
  .cart .cart3 .main .right .bigimg {
    width: 263px;
    height: 149px;
  }
  .cart .cart3 .main .right .name {
    font-size: 27px;
    margin-top: 39px;
    width: 429px;
  }
  .cart .cart3 .main .right .qty {
    font-size: 19px;
    margin-top: 13px;
  }
  .cart .cart3 .main .right .smalimg {
    width: 141px;
    height: 53px;
    margin-top: 13px;
  }
  .cart .cart3 .main .right .price {
    color: #ff8800;
    margin-top: 13px;
    font-size: 19px;
  }
}

.mobilecart .topheader {
  display: flex;
  justify-content: space-between;
}
.mobilecart .topheader .title {
  font-size: 1.714286rem;
  width: 66%;
  flex-shrink: 0;
}
.mobilecart .topheader .ul {
  display: flex;
}
.mobilecart .topheader .ul .li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobilecart .topheader .ul .li span {
  width: 1.428571rem;
  height: 1.428571rem;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #000000;
  text-align: center;
  line-height: 1.428571rem;
}
.mobilecart .topheader .ul .li i {
  width: 1rem;
  height: 0.214286rem;
  background: #000;
  display: inline-block;
  vertical-align: middle;
}
.mobilecart .topheader .ul .li.active span {
  background: #000;
  color: white;
}

.mobilecart .cart3 .main {
  display: flex;
  justify-content: space-between;
}
.mobilecart .cart3 .main .left .title {
  font-weight: 350;
  text-align: left;
  font-size: 1.714286rem;
  margin-top: 0;
  margin-top: 1.5rem;
}
.mobilecart .cart3 .main .left .form .group {
  display: flex;
  margin-top: 1.428571rem;
}
.mobilecart .cart3 .main .left .form .group input {
  flex: 1;
  width: 100%;
  border-bottom: 1px solid #000;
  padding-bottom: 0.714286rem;
  padding-left: 0;
}
.mobilecart .cart3 .main .left .form .group input + input {
  margin-left: 0.714286rem;
}
.mobilecart .cart3 .main .left .form .group input::-webkit-input-placeholder,
.mobilecart .cart3 .main .left .form .group input::-moz-placeholder,
.mobilecart .cart3 .main .left .form .group input:-ms-input-placeholder,
.mobilecart .cart3 .main .left .form .group input:-moz-placeholder {
  color: #9b9b9b;
}
.mobilecart .cart3 .main .left .form p {
  font-size: 0.857143rem;
  margin-top: 0.857143rem;
  color: #000;
}
.mobilecart .cart3 .main .left .checkbox {
  margin-top: 1.428571rem;
  margin-bottom: 1.428571rem;
}
.mobilecart .cart3 .main .left .checkbox label {
  padding-left: 0px;
  font-size: 0.857143rem;
}
.mobilecart .cart3 .main .left .carttop {
  top: 70px;
  width: 100%;
  height: 20.642857rem;
  background: #ededed;
  left: 0;
}
.mobilecart .cart3 .main .left .carttop .title {
  padding-top: 4.785714rem;
  text-align: center;
  margin-top: 0;
}
.mobilecart .cart3 .main .left .carttop p {
  width: 22.285714rem;
  margin: 0 auto;
  margin-top: 1.571429rem;
  margin-bottom: 2rem;
}
.mobilecart .cart3 .main .left p {
  width: 100%;
  margin: 0 auto;
  margin-top: 1.071429rem;
}
.mobilecart .cart3 .main .left .tit {
  margin-top: 1.428571rem;
  margin-bottom: 0.714286rem;
}
.mobilecart .cart3 .main .left .group {
  display: flex;
  margin-top: 1.428571rem;
  border-bottom: 1px solid #000;
  padding-bottom: 0.714286rem;
}
.mobilecart .cart3 .main .left .group input {
  flex: 1;
  width: 100%;
  padding-left: 0;
  margin-left: 0.714286rem;
}
.mobilecart .cart3 .main .left .group input + input {
  margin-left: 0.714286rem;
}
.mobilecart .cart3 .main .left .group input::-webkit-input-placeholder,
.mobilecart .cart3 .main .left .group input::-moz-placeholder,
.mobilecart .cart3 .main .left .group input:-ms-input-placeholder,
.mobilecart .cart3 .main .left .group input:-moz-placeholder {
  color: #9b9b9b;
}
.mobilecart .cart3 .main .left .topbtn {
  width: 16.857143rem;
  height: 3.214286rem;
  border: 2px solid #1a1a1a;
  line-height: 3.214286rem;
  text-align: center;
  margin-top: 2.5rem;
  margin: 0 auto;
  text-transform: uppercase;
}
.mobilecart .cart3 .main .left .btn {
  width: 100%;
  height: 3.214286rem;
  background: #f8e71c;
  line-height: 3.214286rem;
  text-align: center;
  float: left;
  padding: 0;
  margin-bottom: 1.428571rem;
  margin-top: 0px;
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .cart .topheader {
    display: none;
  }
  .cart .cart3 .main .left p {
    width: auto;
  }
}

/* Cart4 */
.mobilecart4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  text-align: center;
}
.mobilecart4 .title {
  font-size: 1.714286rem;
  margin-bottom: 1.571429rem;
  margin-top: 0;
}
.mobilecart4 p {
  width: 22.142857rem;
  margin-top: 1.571429rem;
  margin-bottom: 2.142857rem;
}
.mobilecart4 u {
  color: #ff8800;
  font-size: 1rem;
  cursor: pointer;
}

.cart4 .main {
  margin-top: 80px;
}

.cart4 .title {
  font-size: 36px;
}

.cart4 p {
  width: 468px;
  margin-bottom: 15px;
}

.cart4 u {
  color: #ff8800;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (min-width: 2000px) {
  .cart4 .title {
    font-size: 48px;
  }
  .cart4 p {
    width: 624px;
    margin-bottom: 20px;
    font-size: 19px;
  }
  .cart4 u {
    color: #ff8800;
    font-size: 27px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .cart4 p {
    width: auto;
  }
}

/* Payment */
.payment-page .title {
  font-weight: 350;
  color: #000;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 36px;
}
.payment-page .form-top {
  display: flex;
}
.payment-page .left-side {
  width: 50%;
  padding-right: 50px;
}
.payment-page .right-side {
  width: 50%;
}
.payment-page .label-heading {
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-bottom: 3px;
}
.payment-page .form-row {
  margin-bottom: 20px;
}
.payment-page label {
  font-size: 18px;
}
.payment-page .field-content {
  display: inline-block;
  vertical-align: middle;
}
.payment-page input[type="radio"] {
  margin-right: 20px;
}
.payment-page input[type="text"] {
  border: 1px solid #777;
  padding: 10px;
  font-size: 18px;
}
.payment-page .compulsory {
  color: red;
}
.payment-page .card-logos {
  height: 30px;
}
.payment-page .single-text {
  width: 100%;
}
.payment-page .flex-holder {
  display: flex;
}
.payment-page .flex-holder input {
  margin-right: 5px;
  flex-grow: 1;
  box-sizing: border-box;
  min-width: 0;
}
.payment-page .flex-holder input:last-child,
.payment-page .flex-holder select:last-child {
  margin-right: 0px;
}
.payment-page select {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  border: 1px solid #777;
  border-radius: 0px;
  font-size: 18px;
  background-color: #fff;
  width: 100%;
  margin-right: 5px;
  height: 40px;
}
.payment-page .ccv {
  width: 50%;
  margin-right: 10px;
}
.payment-page .ccv-help {
  cursor: pointer;
}
.payment-page .ccv-mask {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9990;
}
.payment-page .ccv-example-container {
  width: 560px;
  height: 360px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -180px;
  margin-left: -280px;
  padding: 30px;
  z-index: 9991;
  background-color: #fff;
}
.payment-page .ccv-example-container-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: underline;
}
.payment-page .filled-in-field {
  color: #28a6d4;
  font-size: 16px;
}
.payment-page .pi-notes-msg,
.payment-page .sa-notes-msg {
  cursor: pointer;
  text-decoration: underline;
  color: #555;
  margin-bottom: 10px;
  font-size: 14px;
}
.payment-page .pi-notes-container,
.payment-page .sa-notes-container {
  margin-bottom: 20px;
  border: 1px solid #999;
  padding: 10px;
  height: 200px;
  overflow: scroll;
  font-size: 14px;
}
.payment-page .hy-terms-consent-wrapper {
  position: relative;
  height: 230px;
  margin: 20px 0;
}
.payment-page #hy-terms-consent {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  opacity: 0;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
}
.payment-page .hy-terms-consent-wrapper label {
  padding-left: 0;
  /*position: absolute;
  left: 30px;
  top: 0;*/
  height: 18px;
  line-height: 21px;
  /*cursor: pointer;*/
  cursor: default;
  margin: 0;
  padding: 0;
  font-size: 14px;
} /*
.payment-page .hy-terms-consent-wrapper label:before {
  
  content: "";
  position: absolute;
  left: -30px;
  top: 0px;
  width: 17px;
  height: 17px;
  border: 1px solid #000;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}
.payment-page .hy-terms-consent-wrapper label:after {
  content: "";
  position: absolute;
  left: -27px;
  top: 4px;
  width: 11px;
  height: 11px;
  border: 0;
  border-right: 1px solid #FFF;
  border-left: 1px solid #FFF;
  background: #fff;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
}*/
.payment-page
  .hy-terms-consent-wrapper
  input[type="checkbox"]:checked
  + label:before {
  background: white;
  border-color: #000;
}
.payment-page
  .hy-terms-consent-wrapper
  input[type="checkbox"]:checked
  + label:after {
  background: #000;
}
.payment-page .btn-submit-hy-payment {
  padding: 15px 60px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #5da425;
  display: block;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 300px;
  cursor: pointer;
}
.payment-page .hy-notice {
  margin-bottom: 10px;
  text-align: center;
}
.payment-page .hy-payment-footer {
  display: flex;
  text-align: center;
  margin-top: 50px;
}
.payment-page .hy-payment-footer .footer-section-wrapper {
  margin-bottom: 50px;
  flex-grow: 1;
  flex-basis: 0;
}
.payment-page .hy-payment-footer .footer-label {
  margin-bottom: 20px;
}
.payment-page .ssl-logo {
  width: 160px;
}
.payment-page .form-error {
  text-align: center;
  margin-bottom: 20px;
}
.payment-page .hy-loader-container {
  display: none;
  width: 300px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.payment-page .hy-loader {
  width: 45px;
  height: 45px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.payment-page .hy-loader img {
  width: 100%;
  height: 100%;
}
.payment-page .hy-loader-msg {
  margin-bottom: 20px;
  font-size: 20px;
  color: red;
}

@media screen and (max-width: 700px) {
  .payment-page .form-top {
    display: block;
  }
  .payment-page .left-side {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 50px;
  }
  .payment-page .right-side {
    width: 100%;
    margin-bottom: 70px;
  }
  .payment-page .hy-terms-consent-wrapper {
    height: 400px;
  }
  .payment-page .hy-payment-footer {
    display: block;
  }
  .payment-page .ccv-example-container {
    width: 100%;
    height: auto;
    left: auto;
    top: 50%;
    margin-left: 0;
    margin-top: -75px;
  }
  .payment-page .ccv-example-container img {
    width: 250px;
  }
  .payment-page .nccc-logo {
    width: 214px;
  }
  .payment-page .hy-logo {
    width: 135px;
  }
  .payment-page .ssl-logo {
    width: 120px;
  }
}

/* Footer */
.stklibfooter.footer-root {
  overflow: hidden;
  background-color: #ffe30c;
  color: #000;
  font-size: 14px;
}

.stklibfooter.footer-root.darkTheme {
  color: #fff;
}

.stklibfooter.footer-root a {
  text-decoration: none;
}

.stklibfooter.footer-root p {
  margin: 0;
  line-height: 1.3;
}

.stklibfooter.footer-root .footer-title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

@media (max-width: 500px) {
  .stklibfooter.footer-root {
    padding: 0 24px 36px 24px;
  }
}

@media (min-width: 501px) and (max-width: 767px) {
  .stklibfooter.footer-root {
    padding: 80px 24px;
  }
}

@media (min-width: 768px) {
  .stklibfooter.footer-root {
    padding: 80px 24px 12px;
  }
}

.stklibfooter .footer-wrapper {
  display: flex;
  max-width: 1000px;
  margin: 0 auto 36px;
}

@media (max-width: 960px) {
  .stklibfooter .footer-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 961px) {
  .stklibfooter .footer-wrapper {
    align-items: flex-start;
    justify-content: space-between;
  }
}

.stklibfooter .footer-edm .footer-title {
  margin-top: 0;
}

.stklibfooter .footer-edm form {
  display: flex;
  margin: 14px 0;
}

.stklibfooter .footer-edm form input {
  height: 42px;
  flex: 1 1 100%;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #000;
  margin-right: 13.33px;
  background-color: transparent;
  border-radius: 0;
  color: #000;
  outline: none;
}

.stklibfooter.footer-root.darkTheme .footer-edm form input {
  border-color: #fff;
  color: #fff;
}

.stklibfooter.footer-root .footer-subscribe {
  color: #000;
}

.stklibfooter.footer-root.darkTheme .footer-subscribe {
  color: #fff;
}

.stklibfooter .footer-edm .footer-disclaimer {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12.6px;
}

.stklibfooter.footer-root.darkTheme .footer-edm .footer-disclaimer {
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 500px) {
  .stklibfooter .footer-edm {
    padding: 60px 24px;
    margin: 0 -24px;
    /* background-color: #000; */
  }
}

@media (min-width: 501px) {
  .stklibfooter .footer-edm {
    max-width: 480px;
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.9);
    border-radius: 8px;
  }
  .stklibfooter.footer-root.darkTheme .footer-edm {
    border: 1px solid rgba(255, 255, 255, 0.9);
  }
}

@media (max-width: 960px) {
  .stklibfooter .footer-edm {
    margin-bottom: 2em;
  }
  .stklibfooter.footer-root .footer-title {
    font-size: 20px;
  }
}

.stklibfooter .footer-edm button {
  width: 130px;
  border: none;
  border: 1px solid #000;
  background: transparent;
  border-radius: 20px;
  cursor: pointer;
}
.stklibfooter.footer-root.darkTheme .footer-edm button {
  border: 1px solid #fff;
}

.stklibfooter .footer-contact {
  min-width: 320px;
  max-width: 400px;
  margin-bottom: 42px;
}

@media (max-width: 420px) {
  .stklibfooter .footer-contact {
    padding: 0 18px;
  }
}

@media (max-width: 960px) {
  .stklibfooter .footer-contact {
    text-align: center;
  }
}

@media (max-width: 960px) {
  .stklibfooter .footer-social {
    text-align: center;
  }
}

.stklibfooter .footer-social .footer-channels {
  display: flex;
}

@media (max-width: 960px) {
  .stklibfooter .footer-social .footer-channels {
    justify-content: center;
  }
}

.stklibfooter .footer-social a {
  display: block;
  width: 32px;
  height: 32px;
  color: #000;
  vertical-align: middle;
}
.stklibfooter.footer-root.darkTheme .footer-social a {
  color: #fff;
}

@media (max-width: 960px) {
  .stklibfooter .footer-social a {
    margin: 0 3.23px;
  }
}

@media (min-width: 961px) {
  .stklibfooter .footer-social a {
    margin-right: 16px;
  }
}

.stklibfooter .footer-legal {
  width: 100%;
  padding-top: 2em;
  border-top: 1px solid rgba(0, 0, 0, 0.24);
  margin-top: 2em;
  font-size: 0.9em;
  line-height: 2;
}

.stklibfooter.footer-root.darkTheme .footer-legal {
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}

.stklibfooter .footer-legal a {
  color: rgba(0, 0, 0, 0.7);
}

.stklibfooter.footer-root.darkTheme .footer-legal a {
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 960px) {
  .stklibfooter .footer-legal {
    text-align: center;
  }
}

.stklibfooter ul.footer-links {
  padding: 0;
  margin: 0;
  list-style: none;
}

.stklibfooter ul.footer-links li {
  display: inline;
}

.stklibfooter ul.footer-links li:not(:last-child)::after {
  display: inline-block;
  width: 2px;
  height: 2px;
  margin: 0 8px;
  background-color: rgba(0, 0, 0, 0.48);
  border-radius: 50%;
  content: "";
  vertical-align: middle;
}

/* Description */
.stk_description_inner {
  position: relative;
  max-width: 1200px;
  padding-right: 100px;
  padding-left: 100px;
  margin-right: auto;
  margin-left: auto;
  font-size: 14px;
}

.stk_description {
  align-self: center;
  padding: 100px 24px;
  margin: 0 auto;
  font-size: 14px;
}
.stk_descriptionTitle {
  margin: 34.56px 0;
  color: #162241;
  font-size: 57.6px;
  font-weight: bold;
}

.stk_description_inner.stk_darkTheme {
  color: white;
}

.stk_description_inner.stk_darkTheme .stk_descriptionTitle {
  color: white;
}

.stk_descriptionTitle.center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .stk_description_inner {
    width: 100%;
    padding-right: 4.6875vw;
    padding-left: 4.6875vw;
    margin: auto;
    font-size: 15.525px;
  }
  .stk_descriptionTitle {
    font-size: 32px;
    margin: 19.2px 0;
    font-weight: bold;
  }
}

.ReactModal__Overlay {
  z-index: 9999;
}

.cart .main .tabel .tabelmain .desc .desctext .toptext {
  word-break: break-word;
}